import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

class AnonymousRoute extends Component {

  render() {

    const { authentication: { authenticated }, component: Component, ...routeProps } = this.props;

    return (
      <Route {...routeProps} render={props => (
        authenticated ? (<Redirect to={{ pathname: '/', state: { from: props.location } }} />) : (<Component {...props} />)
      )} />
    );
  }

}

const mapStateToProps = state => ({
  authentication: state.authentication
});

export default connect(mapStateToProps)(AnonymousRoute);
