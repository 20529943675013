// Prime React styles
import 'primereact/resources/primereact.min.css';
// import 'primereact/resources/themes/omega/theme.css';

// Application specific styles
import '../scss/app.scss';

// Font awesome icons
import 'font-awesome/css/font-awesome.css';
// import 'primeicons/primeicons.css';

import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from './store';
import { setRequestInterceptor, configAmplify } from './utils/axios.configuration';
import RootView from './components/views/RootView';

configAmplify();
setRequestInterceptor(store);

const render = Component => {
    ReactDOM.render(
            <Provider store={store}>
                <Component />
            </Provider>,
        document.getElementById('app'));
};

render(RootView);

if (module.hot) {
    module.hot.accept('./components/views/RootView', () => {
        const NextApp = require('./components/views/RootView').default;
        render(NextApp);
    });
}

export default module;