import moment from 'moment';
import 'moment-timezone';

import { padString } from './formatting.utils';

export function toUpperCase(value) {
  return value && typeof value === 'string' && value.toUpperCase();
}

export function convertToSlug(string) {
  const a = 'àáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
  const b = 'aaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
  const p = new RegExp(a.split('').join('|'), 'g');

  /*CRA lint rules don't exclude valid regex escapes*/
  return string.toString().toLowerCase()
  /* eslint-disable */
    .replace(/\s+/g, '-') // Replace spaces with
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with ‘and’
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple — with single -
    .replace(/^-+/, ''); // Trim — from start of text .replace(/-+$/, '') // Trim — from end of text
    /* eslint-enable */
}

export function getDateFormat(locale) {
  return locale === 'en' ? 'mm/dd/yy' : 'dd/mm/yy';
}

export function convertValueToDate(timestamp) {
  return timestamp && (typeof timestamp === 'number' || typeof timestamp === 'string') ? moment(timestamp).toDate() : null;
}

export function getDateFromEvent(event, currentDate) {
  currentDate = new Date(currentDate);
  let m = currentDate.getUTCMonth(), d = currentDate.getUTCDate(), y = currentDate.getUTCFullYear();
  switch (event.id) {
    case 'month' : {
      m = event.value;
      break;
    } case 'day' : {
      m = m+1;
      d = event.value;
      break;
    } case 'year' : {
      m = m+1;
      y = event.value;
      break;
    }
    default :{
      // do nothing
    }
  }

  m = padString(m);
  d = padString(d);

  return { y, m, d };
}
