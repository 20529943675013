import { put, takeEvery, select } from "redux-saga/effects";
import axios from 'axios';
import { hide } from 'redux-modal';
import { overSome, pipe, map, startsWith, endsWith, camelCase, omitBy, omit, isArray, isFunction } from 'lodash/fp';
import { push } from 'react-router-redux';
import * as config from 'js/configuration/app.configuration';
import actions from 'js/actions/api.actions';
import * as fileActions from 'js/actions/file.actions';
import { getVisibleModalName } from 'js/selectors';
import * as requestBuilders from 'js/saga/utils/requestBuilders';
import * as responseHandlers from 'js/saga/utils/responseHandlers';
import { replace } from "js/utils";
import { toQueryParams } from "js/utils/query-string.utils";
import { apiActions, apiVerbs, otherVerbs, apiActionDelimiter, setModal, fileVerbs } from "js/utils/action.utils";
import { trimObjectStrings } from "../utils/formatting.utils";
import ENV from 'js/configuration/env';
import { modalName as fileDownloadFailModalName } from 'js/components/modals/FileDownloadFailModal';

const mockData = {
  searchTrip: [],
  fetchPayment: []
};

const idParam = '/{id}';
const id2Param = '/{id2}';
const id3Param = '/{id3}';
const cmdParam = '/{cmd}';
const urlSuffix = '_API_URL';
const cleanParams = omitBy((_, k) => startsWith(apiActionDelimiter)(k));
const doNotCloseModalActs = [actions.car, actions.carUpdateValidate];
const doNotAddId = [actions.car];

function* hideModal(action, act) {
  if (!isGet(action) && !isValidate(action) && doNotCloseModalActs.indexOf(act) === -1) {
    const modalName = yield select(getVisibleModalName);
    if (modalName) {
      yield put(hide(modalName));
      yield put(setModal(modalName, 'visible', false));
    }
  }
}

export function* api({ payload: { action, params, ...p } }) {
  params = params || {};
  const origParams = { ...params };
  const act = action.split(apiActionDelimiter).pop();
  const camelAction = camelCase(action);
  const isGetAction = isGet(action);
  const isValidateAction = isValidate(action);
  const isPostAction = isPost(action);
  const isPatchAction = isPatch(action);
  const isDeleteAction = isDelete(action);
  const urlKey = action + urlSuffix;
  let url = origParams.url || config[urlKey];
  if (!url) {
    url = config[act + urlSuffix];
    url.indexOf(cmdParam) !== -1 && url.replace(cmdParam, p.cmd || '');
    url.indexOf(id2Param) !== -1 && url.replace(id2Param, p.id2 || '');
    url.indexOf(id3Param) !== -1 && url.replace(id3Param, p.id3 || '');
    if (!endsWith(idParam, url) && (isPatchAction || isDeleteAction) && (doNotAddId.indexOf(act) === -1))
      url += idParam;
  }

  if (requestBuilders[camelAction])
    params = requestBuilders[camelAction](params);

  params = cleanParams(params);
  trimObjectStrings(params);
  if (isGetAction)
    params = toQueryParams(params);

  if (params && params.urlQuery) {
    url += '?';
    for (let key in params.urlQuery) {
      url += key + '=' + params.urlQuery[key] + '&';
    }
    url = url.slice(0, -1);
  }

  const paramsWithoutId = omit(['id', 'url', 'origData', 'form'], params);

  let data = {};
  let serverError = null;

  try {
    data = yield (mockData[camelAction]
      ? Promise.resolve({ data: { results: mockData[camelAction] } })
      : http(action)(
        replace(url, origParams),
        isGetAction ? { params: paramsWithoutId } : ((isPostAction) || (isPatchAction && endsWith(idParam, url)) ? paramsWithoutId : params)
      ))
      .then(r => r.data);
  } catch (error) {
    serverError = error;
    yield putError(p.errMsg || error, origParams, action);

    if (p.fail_functions) {
      if (isArray(p.fail_functions)) {
        for (const a of p.fail_functions)
          a(serverError);
      }
      isFunction(p.fail_functions) && p.fail_functions(serverError);
    }
  }

  const rh = (responseHandlers[camelAction] && responseHandlers[camelAction](data, params, serverError)) || {};
  if (rh.error) {
    serverError = rh.error;
    yield hideModal(action, act);
    yield putError(rh.error, origParams, action);
  }

  if (rh.api) { // replace the result with another api call
    yield put(rh.api);
  } else {

    data = rh.data || data;

    const id = camelCase(`${act}Id`);
    if (isPostAction && data && data[id]) {
      origParams.id = data[id];
    }
    const payload = { params: origParams, action, data };
    const { __goto } = origParams;

    if (rh.actions) {
      for (const a of rh.actions)
        yield put(a);
    }
    if (p.all_actions) {
      if (isArray(p.all_actions)) {
        for (const a of p.all_actions)
          yield put(a);
      } else {
        yield put(p.all_actions);
      }
    }
    if (p.all_functions) {
      if (isArray(p.all_functions)) {
        for (const a of p.all_functions)
          a(data, params, serverError);
      }
      isFunction(p.all_functions) && p.all_functions(data, params, serverError);
    }

    if (!serverError) {
      yield put({
        type: apiActions.API_SUCCESS,
        payload
      });

      if (rh.success_actions) {
        for (const a of rh.success_actions)
          yield put(a);
      }
      if (p.success_actions) {
        if (isArray(p.success_actions)) {
          for (const a of p.success_actions)
            yield put(a);
        } else {
          yield put(p.success_actions);
        }
      }
      if (p.success_functions) {
        if (isArray(p.success_functions)) {
          for (const a of p.success_functions)
            a(data, params, serverError);
        }
        isFunction(p.success_functions) && p.success_functions(data, params, serverError);
      }


      yield hideModal(action, act);

      if (!isGetAction && !isValidateAction) {
        yield put(actions[camelCase(otherVerbs.NOTIFY + '.' + act)]({ ...origParams, ...data }));
      }

      if (__goto) {
        yield put(push(__goto));
      }
    }
  }
}

function* putError(error, origParams, action) {
  yield put({
    type: apiActions.API_ERROR,
    payload: { params: origParams, action, error }
  });
  if (ENV.IS_DEVELOPMENT) console.error(error);
}

function* fileUpload({ path, files, formName, formDataName, successFn, failFn }) {
  yield put(fileActions.filePending(formName));
  const urlKey = path + urlSuffix;
  let url = config[urlKey] || path;
  let result;
  const data = new FormData();
  files.forEach((file, i) => data.append(formDataName + (files.length > 1 ? ('[' + i + ']') : ''), files[i]));

  try {
    result = yield axios
      .post(url ? url : config.FILE_UPLOAD_API_URL, data)
      .then(({ data }) => {
        return data;
      });
    yield put(fileActions.fileSuccess(formName, result));
    yield successFn && successFn(result);
  } catch (error) {
    yield put(fileActions.fileFailed(formName, error));
    console.error(error);
    yield failFn && failFn(result);
  }
}

function* fileDownload(action) {
  try {
    yield axios.get(action.path, { responseType: 'blob' }).then(({ data, request: { responseURL }, headers }) => {
      let fileName = responseURL.slice(responseURL.lastIndexOf('/') + 1);
      let fileType = headers['content-type'];
      let file = new Blob([data], { type: fileType });

      const url = window.URL.createObjectURL(file);
      let link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  } catch (error) {
    console.error(error);
    yield put(setModal(fileDownloadFailModalName, 'visible', true));
  }
}

export function* watchAPI() {
  yield takeEvery(apiActions.API_PENDING, api);
  yield takeEvery(fileVerbs.UPLOAD_FILE, fileUpload);
  yield takeEvery(fileVerbs.DOWNLOAD_FILE, fileDownload);
}

const startsWithVerb = pipe(map(startsWith), overSome);
const isGet = startsWithVerb([apiVerbs.FETCH, apiVerbs.SEARCH, apiVerbs.GVALIDATE]);
const isPost = startsWithVerb([apiVerbs.ADD, apiVerbs.VALIDATE]);
const isPatch = startsWithVerb([apiVerbs.UPDATE]);
const isDelete = startsWithVerb([apiVerbs.DELETE]);
const isValidate = startsWithVerb([apiVerbs.VALIDATE]);
const http = x => isPost(x) ? axios.post : (isPatch(x) ? axios.patch : (isDelete(x) ? axios.delete : axios.get));
