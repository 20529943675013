import axios from 'axios';
import Amplify from 'aws-amplify';

import { logout } from '../actions/authentication.actions';
import {SUPPRESS_AUTHORIZATION_URLS} from 'js/configuration/app.configuration';

import { AMPLIFY_CONFIG } from '../configuration/app.configuration';

export function configAmplify() {
  Amplify.configure(AMPLIFY_CONFIG);
}

export function getAuthentication() {
  const { Auth: { userPoolWebClientId } } = AMPLIFY_CONFIG;
  const userNmae = localStorage.getItem(`CognitoIdentityServiceProvider.${userPoolWebClientId}.LastAuthUser`);

  return userNmae && localStorage.getItem(`CognitoIdentityServiceProvider.${userPoolWebClientId}.${userNmae}.idToken`);
}

export function setRequestInterceptor(store) {
  axios.interceptors.request.use((config) => { // intercept requests to inject access token when authorization is not yet present
    if (config.headers) {
      if (!config.headers.Authorization) {
        const token = getAuthentication();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        } else { // when there is no token, gateway will not respond with a (401) error; so, logout user to force relogin when attempting to make a request w/o a token
          store.dispatch(logout(true));
        }
      }

      if (SUPPRESS_AUTHORIZATION_URLS.indexOf(config.url) !== -1) {
        delete config.headers.Authorization;
      }
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  axios.interceptors.response.use((response) => response, (error) => {
    if (error.response && error.response.status === 401) { // when we do get a 401, logout user
      store.dispatch(logout(true));
    }
    return Promise.reject(error);
  });
}
