import { replace } from 'react-router-redux';
import { stringify } from 'query-string';
import { get, isEmpty, pickBy, isNumber, uniq, keyBy, orderBy } from 'lodash/fp';
import apiActions from 'js/actions/api.actions';
import { show, hide } from 'redux-modal';
import { APPLY_PROMO_CODE_MODAL_NAME } from 'js/components/modals/ApplyPromoCodeModal';
import { ADD_CAR_MODAL } from 'js/components/modals/AddCarModal';
import { STAFF_API_URL } from 'js/configuration/app.configuration';
import { setModal } from 'js/utils/action.utils';
import { updateCustomerDetailGenericError, updateCustomerDetailGenericErrorKey } from 'js/constants/customer';
import moment from 'moment-timezone';

const { fetchFreeMinuteSummary, searchFreeMinute, resetFreeMinute, fetchTicket: fetchTicketAction, fetchProfileNotes: fetchProfileNotesAction,
  fetchCustomerDetail, fetchAccountSummary, fetchPersonalInformation, fetchSchool, setDiagnosticStatus, fetchBillingSummary, fetchFleetservicezones, fetchPendingVehicles } = apiActions;
const validateDiagnostics = apiActions.validateDiagnostic;
export const searchCustomer = (data, params) => ({
  actions: [
    replace({ search: '?' + stringify(pickBy((val) => (Array.isArray(val) ? !isEmpty(val) : !!val), params)) })
  ]
});

export const searchTicket = searchCustomer;

export const fetchInfleetform = (data) => ({
  actions: [
    show(ADD_CAR_MODAL, { formData: data || {} })
  ]
});

export const fetchPromotion = (data) => {
  return data.results && data.results.length === 1 ? {
    actions: [
      setModal(APPLY_PROMO_CODE_MODAL_NAME, 'visible', true),
      setModal(APPLY_PROMO_CODE_MODAL_NAME, 'promotion', get('results[0]', data) || {})
    ]
  } : { error: 'MODALS.APPLY_PROMO_CODE.FORM_VALIDATION.INVALID_PROMO_CODE' };
};

export const gvalidatePromotion = (data) => {
  return data && data.status === 'ACTIVE' ? {
    actions: [
      setModal(APPLY_PROMO_CODE_MODAL_NAME, 'visible', true),
      setModal(APPLY_PROMO_CODE_MODAL_NAME, 'promotion', data)
    ]
  } : { error: 'MODALS.APPLY_PROMO_CODE.FORM_VALIDATION.INVALID_PROMO_CODE' };
};

export const addApplyPromo = (data, { customerId }) => {
  if (!data.success) {
    return { error: data.description, actions: [setModal(APPLY_PROMO_CODE_MODAL_NAME, 'visible', false)] };
  }
  return ({
    actions: [
      setModal(APPLY_PROMO_CODE_MODAL_NAME, 'visible', false),
      fetchFreeMinuteSummary({ id: customerId, customerId }),
      searchFreeMinute({ customerId })
    ]
  });
};

export const addFleetservicezones = () => {
  return ({
    success_actions: [
      fetchFleetservicezones({includeInactive: true})
    ]
  });
};

export const addHomeZoneActivation = () => {
  return ({
    success_actions: [
      fetchFleetservicezones({includeInactive: true})
    ]
  });
};
export const deleteHomeZoneActivation = () => {
  return ({
    success_actions: [
      fetchFleetservicezones({includeInactive: true})
    ]
  });
};

export const addCar = (data, params, error) => get('response.status', error) === 502 ? {
  actions: [
    hide(ADD_CAR_MODAL)
  ]
} : {};

export const validateInfleet = (data, values) => {
  const errors = {};

  Object.keys(values).forEach((key) => {
    if (data[key] && !data[key].valid) {
      errors[key] = (!data[key].unique) ? "notUnique" : "invalid";
    }
  });
  if (Object.entries(errors).length > 0) {
    return { error: errors };
  }
  return ({
  });
};

export const fetchBillingFee = (data, params) => {
  const { type } = params;
  if (type) {
    data = data.filter((fee) => fee.type === type);
  }
  data.sort((a, b) => (`${a.type} ${a.name}` < `${b.type} ${b.name}` ? -1 : 1));
  return { data };
};

export const fetchTicket = (data, params) => {
  const notes = data.notes || [];
  data.customers = (data.customers || []).map(c => ({ ...c, fullName: `${c.firstName} ${c.preferredName} ${c.lastName}` }));
  data.staff = data.staff && { ...data.staff, fullName: `${data.staff.firstName} ${data.staff.lastName} (${data.staff.role})` };
  if (params.origData) {
    const staffMap = keyBy('id', data.results);
    params.origData.notes.forEach(n => n.createdBy = staffMap[n.createdBy]);
    params.origData.notes = orderBy(['createDateTime'], ['desc'], (params.origData.notes || []));
    return { data: params.origData };
  } else if (notes.length > 0 && notes.every(n => isNumber(n.createdBy))) {
    const staffIds = uniq(notes.map(n => n.createdBy)).join();
    return { api: fetchTicketAction({ ...params, url: STAFF_API_URL, staffIds, origData: data }) };
  }
  return { data };
};

export const fetchBillingNetFuelReimbursement = (data) => {
  if (!data.netAmount) {
    throw new Error('Invalid netAmount');
  }
  return { data };
};

export const updateCustomerDetail = (data, params) => ({
  success_actions: [
    fetchCustomerDetail({ id: params.id })
  ]
});

export const addFreeMinute = (data, { customerId }) => ({
  success_actions: [
    fetchFreeMinuteSummary({ id: customerId, customerId }),
    searchFreeMinute({ customerId }),
    resetFreeMinute({ form: 'freeMinute' })
  ]
});

export const validateDiagnostic = (data, params) => {
  let remainingTests = params.listOfRemainingTests;
  let testToRun = [];
  let errors = false;
  let actualData = data[0];

  if (!actualData || !actualData.success) {
    errors = true;
  }
  if (remainingTests.length > 0) {
    testToRun.push(remainingTests.shift());
    return {
      actions: [
        setDiagnosticStatus({ command: params.vin + "_" + params.diagnostics[0], httpStatus: get('details.httpStatus', actualData), error: errors, success: !errors, isLoading: false }),
        setDiagnosticStatus({ command: params.vin + "_" + testToRun[0], httpStatus: get('details.httpStatus', actualData), error: false, success: false, isLoading: true }),
        validateDiagnostics({ vin: params.vin, diagnostics: testToRun, listOfRemainingTests: remainingTests })
      ]
    };
  } else {
    return {
      actions: [
        setDiagnosticStatus({ command: params.vin + "_" + params.diagnostics[0], httpStatus: get('details.httpStatus', actualData), error: errors, success: !errors, isLoading: false })
      ]
    };
  }
};

export const addSpecialFee = (data, params) => ({
  actions: [
    fetchBillingSummary({ id: params.customer.customerId, customerId: params.customer.customerId })
  ]
});

const updateCustomerProcess = h => (data, params) => {
  const success_actions = [h({ id: params.id })];
  if (Object.keys(data).some(k => data[k] === updateCustomerDetailGenericErrorKey || k === 'error'))
    return { error: updateCustomerDetailGenericError, success_actions };
  else
    return { success_actions };
};

export const updateAccountSummary = updateCustomerProcess(fetchAccountSummary);
export const updatePersonalInformation = updateCustomerProcess(fetchPersonalInformation);
export const updateSchool = updateCustomerProcess(fetchSchool);

export const fetchProfileNotes = data => ({
  data: { notes: data }
});

export const addProfileNotes = (_, params) => ({
  actions: [
    fetchProfileNotesAction({ id: params.id })
  ]
});

export const updateProfileNotes = addProfileNotes;
export const deleteProfileNotes = addProfileNotes;

export const fetchTripInfo = data => ({
  data: {
    ...data,
    startDateTime: moment.tz(data.startDateTime, data.vehicleTimeZone),
    endDateTime: moment.tz(data.endDateTime, data.vehicleTimeZone)
  }
});

export const addInfleetingDeployToFleet = () => ({
  actions: [
    fetchPendingVehicles()
  ]
});

export const addInfleetingValidate = () => ({
  success_actions: [fetchPendingVehicles()]
});
