import { formatOptionLabels } from 'js/utils';
import { toPairs } from 'lodash/fp';

const customer = {
  accountStatus: {
    active:  { value: 'ACTIVE', label: 'ACCOUNT_SUMMARY.ACCOUNT_STATUS.ACTIVE'},
    closed: { value: 'CLOSED', label: 'ACCOUNT_SUMMARY.ACCOUNT_STATUS.CLOSED'}
  },
  suspensionStatus: {
    notSuspended:  { value: 'UNSUSPENDED', label: 'ACCOUNT_SUMMARY.SUSPENSION_STATUS.NOT_SUSPENDED'},
    suspendedFlag: { value: 'SUSPENDED-FLAG', label: 'ACCOUNT_SUMMARY.SUSPENSION_STATUS.SUSPENDED'},
    suspendedOsb: { value: 'SUSPENDED-OSB', label: 'ACCOUNT_SUMMARY.SUSPENSION_STATUS.SUSPENDED_OSB'}
  },
  driverRecordStatus: {
    approved: { value: 'APPROVED', label: 'ACCOUNT_SUMMARY.DRIVER_RECORD_STATUS.APPROVED'},
    declined: { value: 'DECLINED', label: 'ACCOUNT_SUMMARY.DRIVER_RECORD_STATUS.DECLINED'},
    pending: { value: 'PENDING', label: 'ACCOUNT_SUMMARY.DRIVER_RECORD_STATUS.PENDING'}
  },
  preferredLanguage: {
    active:  { value: 'EN', label: 'ACCOUNT_SUMMARY.PREFERRED_LANGUAGE.EN'},
    closed: { value: 'FR', label: 'ACCOUNT_SUMMARY.PREFERRED_LANGUAGE.FR'}
  },
  rfidStatus: {
    active: 'ACTIVE',
    inactive: 'INACTIVE',
  }
};

export default customer;

export const accountStatusOptions = formatOptionLabels(toPairs(customer.accountStatus).map(x => x[1]));

export const suspensionStatusOptions = formatOptionLabels(toPairs(customer.suspensionStatus).map(x => x[1]));

export const driverRecordStatusOptions = formatOptionLabels(toPairs(customer.driverRecordStatus).map(x => x[1]));

export const updateCustomerDetailGenericErrorKey = 'apiUpdateError';
export const updateCustomerDetailGenericError = "There's been an error saving some of your changes.";