import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

const GenericErrorMessage = () => (
  <div className="error-message">
    <h3 className="text-center text-error">
      <i className="fa fa-warning"></i><br />
      <FormattedMessage id='GLOBAL.GENERIC_ERROR_MESSAGE' />
    </h3>
  </div>
);

export default injectIntl(GenericErrorMessage);
