import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Router } from 'react-router-dom';
import { history } from 'js/store';
import { IntlProvider } from 'react-intl-redux';
import english from 'react-intl/locale-data/en';
import french from 'react-intl/locale-data/fr';
import { addLocaleData } from 'react-intl';
import ErrorBoundary from '../elements/ErrorBoundary';
import RootNavigation from '../elements/RootNavigation';
import RootBreadcrumbs from '../elements/RootBreadcrumbs';
import { primaryRoute, secondaryRoute } from 'js/routes';
import { getAuthenticatedUser } from 'js/actions/authentication.actions';
import { withMount, withState } from 'js/utils/with';

addLocaleData([...english, ...french]);

const RootView = ({ screenLayout: { allowSplitScreen, splitScreenIsOpen }, intl, authentication: { authenticated, loading }, checked }) =>
  <IntlProvider intlSelector={() => intl}>
    <Router history={history}>
      <ErrorBoundary>
        {authenticated && <header className="header-container">
          <RootNavigation allowSplitScreen={allowSplitScreen} />
          <RootBreadcrumbs splitScreenIsOpen={splitScreenIsOpen} />
        </header>}

        {checked && !loading &&
          <div className={classNames('main-container', { 'split-screen-open': splitScreenIsOpen })}>
            <div className="primary-content">
              {primaryRoute}
            </div>
            {splitScreenIsOpen && <div className="secondary-content">
              {secondaryRoute}
            </div>}
          </div>
        }
      </ErrorBoundary>
    </Router>
  </IntlProvider>;

export default compose(
  connect(s => ({
    intl: s.intl,
    screenLayout: s.screenLayout,
    authentication: s.authentication
  }), { getAuthenticatedUser }),
  withState('checked'),
  withMount(({getAuthenticatedUser, setChecked}) => {
    getAuthenticatedUser();
    setChecked(true);
  })
)(RootView);
