/**
 * Custom query stringy and parse handler. Used primarily to handle array attributes on top of the default underlying implementation
 */
import { parse } from 'query-string';
import { isArray, pipe, pickBy, mapValues, allPass, negate, startsWith, isNil } from 'lodash/fp';

import { isNumber } from './formatting.utils';

const SEPARATOR = ',';

function checkAndConvertToNumericValue(value) {
  // Check and convert numeric string values to numbers
  return isNumber(value) ? parseFloat(value) : value;
}

export function parseQuery(url) {

  const parsed = parse(url);

  for (let parameter in parsed) {
    if (parsed[parameter] !== undefined && parsed[parameter] !== null) {

      if (typeof parsed[parameter] === "string" && parsed[parameter].includes(SEPARATOR)) {
        // Split comma separated values and return array values
        parsed[parameter] = parsed[parameter].split(SEPARATOR);

        for (let key in parsed[parameter]) {
          parsed[parameter][key] = checkAndConvertToNumericValue(parsed[parameter][key]);
        }

      } else {
        parsed[parameter] = checkAndConvertToNumericValue(parsed[parameter]);
      }
    }
  }

  return parsed;
}

export const toQueryParams = pipe(
  pickBy(allPass([negate(isNil), negate(startsWith('_$'))])),
  mapValues(v => isArray(v) ? v.join(SEPARATOR) : v)
);
