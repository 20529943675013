import { fromPairs, toPairs, map, pipe, camelCase, flatten, over } from 'lodash/fp';

const apiVerbsConsts = ['SEARCH', 'FETCH', 'ADD', 'UPDATE', 'DELETE','VALIDATE', 'GVALIDATE'];
const otherVerbsConsts = ['CLEAR_ERROR', 'RESET', 'SORT', 'COLLAPSE', 'EXPAND', 'TOGGLE', 'NOTIFY', 'CLEAR_NOTIFICATION', 'CLEAR_SUCCESS', 'CLEAR_API', 'EDIT', 'SETFORM', 'CLEAR_FORMS'];
const apiActionsConsts = ['API_PENDING', 'API_SUCCESS', 'API_ERROR', 'API_OTHER'];
const modalActionConsts = ['MODAL_SET'];
const fileVerbsConsts = ['UPLOAD_FILE', 'DOWNLOAD_FILE', 'RESET_FILE', 'RESET_FILE_ERROR', 'RESET_FILE_STATUS'];
const fileActionsConsts = ['FILE_PENDING', 'FILE_FAILED', 'FILE_SUCCESS'];

export const apiActionDelimiter = '__';

export const constArraytoObj = pipe(map(x => [x, x]), fromPairs);
export const constArraytoCamelObj = pipe(map(x => [camelCase(x), x]), fromPairs);

export const apiVerbs = constArraytoObj(apiVerbsConsts);
export const otherVerbs = constArraytoObj(otherVerbsConsts);
export const apiActions = constArraytoObj(apiActionsConsts);
export const modalVerbs = constArraytoObj(modalActionConsts);
export const fileVerbs = constArraytoObj(fileVerbsConsts);
export const fileActions = constArraytoObj(fileActionsConsts);

export const createAction = type => payload => ({ type, payload });

export const constArraytoActionObj = pipe(map(x => [camelCase(x), createAction(x)]), fromPairs);

const mapVerb = d => v => a => v + d + a;
const mapVerbSet = mapVerb('_')('SET');
export const constArraytoSetObj = pipe(map(mapVerbSet), constArraytoObj);
export const constArraytoSetActionObj = pipe(map(mapVerbSet), constArraytoActionObj);

const mapVerbAll = mapVerb(apiActionDelimiter);
const createVerbAction = (verbs, type) => action => verbs.map(v => mapVerbAll(v)(action)).map(x => [
  camelCase(x),
  (payload, p) => createAction(type)({ action: x, params: payload, ...p })
]);
const createModalVerbAction = (verbs) => action => verbs.map(v => v + apiActionDelimiter + action).map((x, i)=> [
  camelCase(x),
  payload => createAction(verbs[i])({ action: x, params: payload })
]);


const createAPIAction = createVerbAction(apiVerbsConsts, apiActions.API_PENDING);

const createAPIOtherAction = createVerbAction(otherVerbsConsts, apiActions.API_OTHER);

export const constArraytoAPIActionObj = pipe(
  over([
    map(createAPIAction),
    map(createAPIOtherAction)
  ]),
  flatten,
  flatten,
  fromPairs
);

const SET_LOCAL = 'SET_LOCAL';
const setLocal = path => value => ({ type: SET_LOCAL, payload: { path, value } });

export const MODAL_SET = 'MODAL_SET';
export const setModal = (path, field, value) => ({ type: MODAL_SET, path, field, value });

export const createLocalActions = pipe(
  toPairs,
  map(x => x[1].map(y => x[0] + '.' + y).map(y => [camelCase('set.' + y), setLocal(y)])),
  flatten,
  fromPairs
);

const createModalAction = createModalVerbAction(modalActionConsts);

export const constArraytoModalActionObj = pipe(
  map(createModalAction),
  flatten,
  fromPairs
);
