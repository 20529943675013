import { flattenAll } from "../utils";

// roles
const ROLE_SYSADMIN = "SYSADM";
const ROLE_MANAGEMENT = "MGMT";
const ROLE_BI = "BI";
const ROLE_MSA = "MSA";
const ROLE_OC = "OC";
const ROLE_FLEET_STAFF = "FLEET";
const ROLE_FLEET_SUPPORT = "SUPPORT";
const ROLE_FLEET_SERVICE = "SVC";
const ROLE_DRIVE = "DRIVE";

// routes
const ROUTE_CARS = ["/cars"];
const ROUTE_CAR_DETAIL = ["/cars/:vin"];
const ROUTE_MEMBERS = ["/customer-search", "/customer-profile", "/customer-profile/:customerId", "/payments/:paymentId"];
const ROUTE_TRIPS = ["/trips", "/trips/:tripId"];
const ROUTE_TICKETS = ["/ticket-search", "/new-ticket", "/edit-ticket/:ticketId", "/ticket-confirmation"];
const ROUTE_PROMOTIONS = ["/promotion-search"];
const ROUTE_MASS_UPLOAD = ["/import"];
const ROUTE_INFLEETING = ["/infleet"];
const ROUTE_HOMEZONE = ["/home-zone"];
const ROUTE_AIMA_PARKING = ["/aima-parking"];
/* Disable wakeup provider until Twilio problem is resolved
const ROUTE_WAKEUP_PROVIDER = ["/wakeup-provider"];
*/
const ROUTE_FLEET_MANAGEMENT = ["/fleet-management"];
const ROUTE_PARKING_TICKET = ["/parking-ticket"];
// [ToDo] const ROUTE_STAFF_MANAGEMENT = "";

export const ALLOWED_ROUTES = {
    [ROLE_SYSADMIN]: flattenAll(ROUTE_CARS, ROUTE_CAR_DETAIL, ROUTE_MEMBERS, ROUTE_TRIPS, ROUTE_TICKETS, ROUTE_PROMOTIONS, ROUTE_MASS_UPLOAD, ROUTE_INFLEETING, ROUTE_HOMEZONE, ROUTE_AIMA_PARKING, ROUTE_FLEET_MANAGEMENT, ROUTE_PARKING_TICKET),
    [ROLE_MANAGEMENT]: flattenAll(ROUTE_CARS, ROUTE_CAR_DETAIL, ROUTE_MEMBERS, ROUTE_TRIPS, ROUTE_TICKETS, ROUTE_PROMOTIONS, ROUTE_MASS_UPLOAD, ROUTE_INFLEETING, ROUTE_HOMEZONE, ROUTE_AIMA_PARKING, ROUTE_FLEET_MANAGEMENT, ROUTE_PARKING_TICKET),
    [ROLE_BI]: flattenAll(ROUTE_CARS, ROUTE_CAR_DETAIL, ROUTE_MEMBERS, ROUTE_TRIPS, ROUTE_TICKETS, ROUTE_PARKING_TICKET),
    [ROLE_MSA]: flattenAll(ROUTE_CARS, ROUTE_CAR_DETAIL, ROUTE_CAR_DETAIL, ROUTE_MEMBERS, ROUTE_TRIPS, ROUTE_TICKETS, ROUTE_PARKING_TICKET),
    [ROLE_OC]: flattenAll(ROUTE_CARS, ROUTE_CAR_DETAIL, ROUTE_MEMBERS, ROUTE_TRIPS, ROUTE_TICKETS, ROUTE_PARKING_TICKET),
    [ROLE_FLEET_STAFF]: flattenAll(ROUTE_CARS, ROUTE_CAR_DETAIL, ROUTE_MEMBERS, ROUTE_TRIPS, ROUTE_TICKETS, ROUTE_INFLEETING, ROUTE_FLEET_MANAGEMENT, ROUTE_PARKING_TICKET),
    [ROLE_FLEET_SUPPORT]: flattenAll(ROUTE_TICKETS, ROUTE_FLEET_MANAGEMENT, ROUTE_PARKING_TICKET),
    [ROLE_FLEET_SERVICE]: flattenAll(ROUTE_CARS, ROUTE_CAR_DETAIL, ROUTE_TICKETS, ROUTE_FLEET_MANAGEMENT, ROUTE_PARKING_TICKET),
    [ROLE_DRIVE]: []
};
