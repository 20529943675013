import { constArraytoCamelObj, constArraytoAPIActionObj, createLocalActions, constArraytoSetObj, constArraytoSetActionObj } from '../utils/action.utils';

const apiActions = [
  'ACTIVITY_LOG',
  'ALERT',
  'BILLING',
  'BILLING_DETAIL',
  'BILLING_FEE',
  'BILLING_PROCESS_PAYMENT',
  'BILLING_SUMMARY',
  'BILLING_NET_FUEL_REIMBURSEMENT',
  'BILLING_MONTHLY_STATEMENTS',
  'BILLING_GENERATE_MONTHLY_STATEMENT',
  'CUSTOMER',
  'CUSTOMER_DETAIL',
  'RFID_CONFLICT',
  'FREE_MINUTE',
  'FREE_MINUTE_SUMMARY',
  'PAYMENT',
  'PROMOTION',
  'CURRENT_STAFF',
  'SPECIAL_FEE',
  'FEE_REVERSAL',
  'TICKET',
  'TICKET_FILE',
  'TRIP',
  'TRIP_DETAIL',
  'VEHICLE',
  'INFLEET',
  'INFLEETFORM',
  'APPLY_PROMO',
  'CAR',
  'CARS',
  'CAR_DETAIL',
  'CAR_WAKEUP',
  'CAR_UPDATE_STATUS',
  'CAR_STATE',
  'TRIP_BY_CAR',
  'TICKET_BY_CAR',
  'REGIONS',
  'SUB_CHANNELS',
  'PENDING_VEHICLES',
  'MASS_IMPORT_FREE_MINUTE',
  'MASS_IMPORT_PROMOTION',
  'MASS_IMPORT',
  'DIAGNOSTIC',
  'FLEETSERVICE',
  'FLEETSERVICEZONES',
  'ACCOUNT_SUMMARY',
  'PERSONAL_INFORMATION',
  'MEMBERSHIP',
  'DRIVER_LICENCE',
  'PROFILE_NOTES',
  'PAYMENT_INFORMATION',
  'SCHOOL',
  'RESERVATION',
  'AVAILABLE_VEHICLES',
  'RESERVATION_GEOCODE',
  'RELATED_CUSTOMERS',
  'HOME_ZONE_MAP',
  'HOME_ZONE_ACTIVATION',
  'HOME_ZONE_AUDIT',
  'TRIP_INFO',
  'TRIP_ROUTE',
  'TRIP_GEOCODE',
  'TRIP_DETAIL_LIST',
  'ACTIVE_TRIP_LIST',
  'TRIP_DETAIL_TROUBLE_SHOOT',
  'END_TRIP',
  'TRIP_BY_CUSTOMER',
  'PARKING_SPOT_LIST',
  'TRIP_ADJ',
  'INFLEETING_RUN_DIAGNOSTICS',
  'INFLEETING_RE_TEST',
  'INFLEETING_DEPLOY_TO_FLEET',
  'INFLEETING_VALIDATE',
  'INFLEETING_DIAGNOSTICS_RESULT',
  'INFLEETING_DIAGNOSTIC_COMMANDS',
  'AIMA_PARKING',
  'AIMA_UPDATE_PARKING',
  'AIMA_ADD_PARKING',
  'PROVIDER',
  'SWITCH_PROVIDER',
  'SWITCH_PROVIDER_FAILURE',
  'VANCOUVER_PARKING_METERS',
  'VANCOUVER_PARKING_LOTS',
  'PARKING_TICKET',
  'CAR_UPDATE_VALIDATE',
  'CAR_HISTORY',
  'CARS_MSISDN',
  'CAR_DATA'
];

const localActions = {
  customer: ['selectedTabIndex']
};

const actions = [
  'LOCAL',
  'FORM',
  'TOUCHED',
  'FOR_SUBMIT',
  'DIAGNOSTIC_STATUS',
  'RESET_DIAGNOSTIC_STATUS'
];

export default {
  ...constArraytoCamelObj(apiActions),
  ...constArraytoAPIActionObj(apiActions),
  ...createLocalActions(localActions),
  ...constArraytoSetObj(actions),
  ...constArraytoSetActionObj(actions)
};
