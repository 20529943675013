import React, { Fragment } from 'react';
import { compose, withProps } from 'recompose';
import { isNumber, find } from 'lodash/fp';
import { injectIntl, FormattedMessage } from 'react-intl';
import ticketSubcategories from 'js/constants/ticket-subcategories';
import { Autocomplete } from './';
import CategoryOptionTemplate from '../extensions/CategoryOptionTemplate';
import TicketCategoriesModal, { TICKET_CATEGORIES_MODAL_NAME } from 'js/components/modals/TicketCategoriesModal';
import { withModal } from 'js/components/elements/ModalControls';

const setCategories = (setForm, formname) => categories =>
  setForm({formname, path: formname + '.categories', value: categories});

const CategorySelector = (({ categories = [], required, formname, showModal, setForm, intl: { formatMessage } }) =>
  <Fragment>
    <Autocomplete name="categories" label={formatMessage({ id: 'TICKET_SEARCH.CATEGORIES' })} multiple={true}
      meta={{ error: categories.length === 0 && 'categories are required' }}
      input={{ onChange: setCategories(setForm, formname), value: categories }} options={ticketSubcategories}
      value={categories || []} labelKey='label' itemTemplate={CategoryOptionTemplate} selectedItemTemplate={CategoryOptionTemplate} required={required} />
    <span id="ticket-search-categories-modal-toggle" className="link" onClick={() => showModal(TICKET_CATEGORIES_MODAL_NAME)}>
      <i className="fa fa-list" />
      <FormattedMessage id='TICKETS.LIST' />
    </span>
    <TicketCategoriesModal initialValues={categories} done={setCategories(setForm, formname)} />
  </Fragment>
);

export default compose(
  withModal(),
  withProps(({ categories }) => ({
    categories: categories && categories.length > 0 && isNumber(categories[0]) ? categories.map(c => find(x => x.value === c, ticketSubcategories)) : categories
  })),
  injectIntl
)(CategorySelector);
