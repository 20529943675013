import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { logout } from '../../actions/authentication.actions';

class Authentication extends Component {

  render() {
    const { authentication: { authenticated, currentUser }, logout, } = this.props;

    if (authenticated) {

      const username = currentUser ? currentUser.username : null;
      const initials = username ? username.substring(0, 1) : null;

      return (
        <span className="authentication floating-menu-container">
          <span className="user-initials">
            {initials}

            <ul className="floating-menu">
              <li><span onClick={logout}><FormattedMessage id='NAVIGATION_LINKS.LOGOUT' /></span></li>
            </ul>
          </span>

          {username && <span className="user-name">{username}</span>}
        </span>
      );

    } else {
      return null;
    }
  }

}

const mapStateToProps = state => ({
  intl: state.intl,
  authentication: state.authentication
});

export default connect(mapStateToProps, { logout })(Authentication);
