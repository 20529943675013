import React from 'react';

import { FormattedMessage } from 'react-intl';
import { Password } from 'primereact/components/password/Password';

export default ({ input, label, required, meta: { touched, error } }) => (
  <div className="input-field input-password">
    {label && <label>{label}</label>}{required && <span> *</span>}
    <Password {...input} feedback={false} />
    {touched && error && <div className="input-field-error"><FormattedMessage id={error} /></div>}
  </div>
);
