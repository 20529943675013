import React, { Fragment } from 'react';
import { isEmpty } from 'lodash/fp';
import actions from "../../actions/api.actions";
import { select } from 'js/selectors';

const { customerDetail } = actions;
const CustomerBreadcrumb = ({ customerDetail }) => {
  const displayCustomerName = !isEmpty(customerDetail.firstName) && !isEmpty(customerDetail.lastName);
  return displayCustomerName ? <Fragment>{customerDetail.firstName + ' ' + customerDetail.lastName}</Fragment> : null;
};

export default select({customerDetail})(CustomerBreadcrumb);
