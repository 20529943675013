import React from 'react';
import { Switch,  Redirect } from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';

import asyncRoute from './async-route';
import Breadcrumbs from './components/elements/Breadcrumbs';
import AuthenticatedRoute from './components/elements/AuthenticatedRoute';
import AnonymousRoute from './components/elements/AnonymousRoute';
import CustomerBreadcrumb from './components/containers/CustomerBreadcrumb';

// Main routes
const HomeView = asyncRoute(() => import(/* webpackChunkName: 'home' */ './components/views/HomeView'));
const LoginView = asyncRoute(() => import(/* webpackChunkName: 'login' */ './components/views/LoginView'));
const PasswordRecoveryView = asyncRoute(() => import(/* webpackChunkName: 'password-recovery' */ './components/views/PasswordRecoveryView'));
const ResetPasswordView = asyncRoute(() => import(/* webpackChunkName: 'reset-password' */ './components/views/ResetPasswordView'));
const PromotionSearchView = asyncRoute(() => import(/* webpackChunkName: 'promotion-search' */ './components/views/PromotionSearchView'));
const TicketsSearchView = asyncRoute(() => import(/* webpackChunkName: 'tickets-search' */ './components/views/TicketsSearchView'));
const EditTicketView = asyncRoute(() => import(/* webpackChunkName: 'edit-ticket' */ './components/views/EditTicketView'));
const TicketInformationView = asyncRoute(() => import(/* webpackChunkName: 'ticket-information' */ './components/views/TicketInformationView'));
const TicketConfirmationView = asyncRoute(() => import(/* webpackChunkName: 'ticket-confirmation' */ './components/views/TicketConfirmationView'));
const CustomerSearchView = asyncRoute(() => import(/* webpackChunkName: 'customer-search' */ './components/views/CustomerSearchView'));
const TripsView = asyncRoute(() => import(/* webpackChunkName: 'trips' */ './components/views/TripsView'));
const CarsView = asyncRoute(() => import(/* webpackChunkName: 'cars' */ './components/views/CarsView'));
const CarDetailView = asyncRoute(() => import(/* webpackChunkName: 'carDetail' */ './components/views/CarDetailView'));
const CustomerProfileView = asyncRoute(() => import(/* webpackChunkName: 'customer-profile' */ './components/views/CustomerProfileView'));
const TripDetailsView = asyncRoute(() => import(/* webpackChunkName: 'trip-details' */ './components/views/TripDetailsView'));
const PaymentDetailsView = asyncRoute(() => import(/* webpackChunkName: 'payment-details' */ './components/views/PaymentDetailsView'));
const InfleetView = asyncRoute(() => import(/* webpackChunkName: 'infleet' */ './components/views/InfleetView'));
const HomeZoneView = asyncRoute(() => import(/* webpackChunkName: 'infleet' */ './components/views/HomeZoneView'));
const AimaParkingView = asyncRoute(() => import('./components/views/AimaParkingView'));
// Disable wakeup provider until Twilio problem is resolved
// const WakeUpProviderView = asyncRoute(() => import('./components/views/WakeUpProviderView'));
const FleetManagementView = asyncRoute(() => import('./components/views/FleetManagementView'));
const ParkingTicketView = asyncRoute(() => import('./components/views/ParkingTicketView'));
const ImportView = asyncRoute(() => import(/* webpackChunkName: 'import' */ './components/views/ImportView'));

// Secondary routes
const RecentActivityView = asyncRoute(() => import(/* webpackChunkName: 'recent-activity' */ './components/views/RecentActivityView'));
const CustomerTicketsView = asyncRoute(() => import(/* webpackChunkName: 'customer-tickets' */ './components/views/CustomerTicketsView'));
const CustomerTripsView = asyncRoute(() => import(/* webpackChunkName: 'customer-trips' */ './components/views/CustomerTripsView'));
const CustomerPaymentsView = asyncRoute(() => import(/* webpackChunkName: 'customer-payments' */ './components/views/CustomerPaymentsView'));
const CarRecentActivityView = asyncRoute(() => import(/* webpackChunkName: 'car-recent-activity' */ './components/views/CarRecentActivityView'));
// Primary navigation routes
const primaryRoute = (
  <Switch>
    <AuthenticatedRoute path='/' exact component={HomeView} />
    <AnonymousRoute path='/login' component={LoginView} />
    <AnonymousRoute path='/password-recovery' exact component={PasswordRecoveryView} />
    <AnonymousRoute path='/reset-password' exact component={ResetPasswordView} />
    <AuthenticatedRoute path='/promotion-search' exact component={PromotionSearchView} />
    <AuthenticatedRoute path='/ticket-search' exact component={TicketsSearchView} />
    <AuthenticatedRoute path='/new-ticket' exact component={EditTicketView} allowSplitScreen={true} />
    <AuthenticatedRoute path='/edit-ticket/:ticketId' exact component={EditTicketView} allowSplitScreen={true} />
    <AuthenticatedRoute path='/ticket-confirmation' exact component={TicketConfirmationView} />
    <AuthenticatedRoute path='/customer-search' component={CustomerSearchView} />
    <AuthenticatedRoute path='/customer-profile' exact component={() => <Redirect to='/customer-search' />} />
    <AuthenticatedRoute path='/customer-profile/:customerId' component={CustomerProfileView} allowSplitScreen={true} />
    <AuthenticatedRoute path='/trips' exact component={TripsView} />
    <AuthenticatedRoute path='/trips/:tripId' exact component={TripDetailsView} />
    <AuthenticatedRoute path='/cars' exact component={CarsView} />
    <AuthenticatedRoute path='/cars/:vin' exact component={CarDetailView} allowSplitScreen={true} />
    <AuthenticatedRoute path='/payments/:paymentId' exact component={PaymentDetailsView} />
    <AuthenticatedRoute path='/infleet' exact component={InfleetView} />
    <AuthenticatedRoute path='/home-zone' exact component={HomeZoneView} />
    <AuthenticatedRoute path='/import' exact component={ImportView} />
    <AuthenticatedRoute path='/aima-parking' exact component={AimaParkingView} />
    {/* <AuthenticatedRoute path='/wakeup-provider' exact component={WakeUpProviderView} /> */}
    <AuthenticatedRoute path='/fleet-management' exact component={FleetManagementView} />
    <AuthenticatedRoute path='/parking-ticket' exact component={ParkingTicketView} />
  </Switch>
);

// Primary breadcrumbs
const primaryBreadcrumbRoutes = [
  { path: '/', breadcrumb: 'BREADCRUMBS.HOME' },
  { path: '/login', breadcrumb: 'BREADCRUMBS.LOGIN' },
  { path: '/password-recovery', breadcrumb: 'BREADCRUMBS.PASSWORD_RECOVERY' },
  { path: '/reset-password', breadcrumb: 'BREADCRUMBS.RESET_PASSWORD' },
  { path: '/promotion-search', breadcrumb: 'BREADCRUMBS.PROMOTIONS' },
  { path: '/ticket-search', breadcrumb: 'BREADCRUMBS.TICKETS' },
  { path: '/new-ticket', breadcrumb: 'BREADCRUMBS.NEW_TICKET' },
  { path: '/edit-ticket', breadcrumb: 'BREADCRUMBS.EDIT_TICKET' },
  { path: '/ticket-confirmation', breadcrumb: 'BREADCRUMBS.TICKET_CONFIRMATION' },
  { path: '/tickets/:ticketId', breadcrumb: 'BREADCRUMBS.TICKET' },
  { path: '/customer-search', breadcrumb: 'BREADCRUMBS.CUSTOMER_SEARCH' },
  { path: '/customer-profile', breadcrumb: 'BREADCRUMBS.CUSTOMER_PROFILE' },
  { path: '/customer-profile/:customerId', breadcrumb: CustomerBreadcrumb },
  { path: '/trips', breadcrumb: 'BREADCRUMBS.TRIPS' },
  { path: '/trips/:tripId', breadcrumb: 'BREADCRUMBS.TRIP' },
  { path: '/cars', breadcrumb: 'BREADCRUMBS.CARS' },
  { path: '/payments/:paymentId', breadcrumb: 'BREADCRUMBS.PAYMENT' },
  { path: '/infleet', breadcrumb: 'BREADCRUMBS.INFLEET' },
  { path: '/home-zone', breadcrumb: 'BREADCRUMBS.HOMEZONE' },
  { path: '/import', breadcrumb: 'BREADCRUMBS.IMPORT' },
  { path: '/aima-parking', breadcrumb: 'BREADCRUMBS.AIMA_PARKING' },
  { path: '/wakeup-provider', breadcrumb: 'BREADCRUMBS.WAKE_UP_PROVIDER' },
  { path: '/fleet-management', breadcrumb: 'BREADCRUMBS.FLEET_MANAGEMENT' },
  { path: '/parking-ticket', breadcrumb: 'BREADCRUMBS.PARKING_TICKET' }
];

const PrimaryBreadcrumbs = withBreadcrumbs(primaryBreadcrumbRoutes, { disableDefaults: true })(Breadcrumbs);

// Secondary navigation routes
const secondaryRoute = (
  <Switch>
    <AuthenticatedRoute path='/new-ticket' exact component={TicketInformationView} allowSplitScreen={true} />
    <AuthenticatedRoute path='/edit-ticket/:ticketId' exact component={TicketInformationView} allowSplitScreen={true} />
    <AuthenticatedRoute path='/customer-profile/:customerId' exact component={RecentActivityView} allowSplitScreen={true} />
    <AuthenticatedRoute path='/customer-profile/:customerId/tickets' exact component={CustomerTicketsView} allowSplitScreen={true} />
    <AuthenticatedRoute path='/customer-profile/:customerId/trips' exact component={CustomerTripsView} allowSplitScreen={true} />
    <AuthenticatedRoute path='/customer-profile/:customerId/payments' exact component={CustomerPaymentsView} allowSplitScreen={true} />
    <AuthenticatedRoute path='/cars/:vin' exact component={CarRecentActivityView} allowSplitScreen={true} />
  </Switch>
);

// Secondary breadcrumbs
const secondaryBreadcrumbRoutes = [
  { path: '/new-ticket', breadcrumb: 'BREADCRUMBS.TICKET_RELATED_INFORMATION' },
  { path: '/edit-ticket/:ticketId', breadcrumb: 'BREADCRUMBS.TICKET_RELATED_INFORMATION' },
  { path: '/customer-profile/:customerId', breadcrumb: 'BREADCRUMBS.RECENT_ACTIVITY' },
  { path: '/customer-profile/:customerId/tickets', breadcrumb: 'BREADCRUMBS.TICKETS' },
  { path: '/customer-profile/:customerId/trips', breadcrumb: 'BREADCRUMBS.TRIPS' },
  { path: '/customer-profile/:customerId/payments', breadcrumb: 'BREADCRUMBS.PAYMENTS' },
  { path: '/cars/:vin', breadcrumb: 'BREADCRUMBS.RECENT_ACTIVITY' },
];

const SecondaryBreadcrumbs = withBreadcrumbs(secondaryBreadcrumbRoutes, { disableDefaults: true })(Breadcrumbs);

const routesWithoutHeader = [
  '/new-ticket',
  '/ticket-confirmation'
];

export function hideHeaderForRoute(pathname) {
  return pathname !== '/' && routesWithoutHeader.some(routeWithoutHeader => routeWithoutHeader.startsWith(pathname));
}

export { primaryRoute, secondaryRoute, PrimaryBreadcrumbs, SecondaryBreadcrumbs, routesWithoutHeader };
