import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Calendar as DatePicker } from 'primereact/components/calendar/Calendar';
import moment from 'moment-timezone';

import { getDateFormat, convertValueToDate } from '../../../utils/forms.utils';

const calendarTranslations = {
  en: {
    firstDayOfWeek: 1,
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Sun', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    today: 'Today',
    clear: 'Clear'
  },
  fr: {
    firstDayOfWeek: 1,
    dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
    monthNames: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'July', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
    today: 'Aujourd\'hui',
    clear: 'Effacer'
  }
};

export default class Calendar extends Component {

  state = {
    touched: false
  }

  render() {
    const { input, label, required, disabled, timeAtStartOfDay, timeAtEndOfDay, dateTimeFormat, minDate, maxDate, locale, meta: { error } } = this.props;

    const { touched } = this.state;

    const mapToExpectedValue = (date, dateTimeFormat) => {
      // Skip conversion if undefined or null
      if (date === undefined || date === null) {
        return date;
      }

      let expectedValue = date;

      if (dateTimeFormat) {
        // TODO add moment timezone support
        // const timezone = moment.tz.guess();
        expectedValue = moment.utc(date).format(dateTimeFormat);

      } else if (timeAtStartOfDay === true) {
        expectedValue = moment.utc(date).startOf('day').valueOf();

      } else if (timeAtEndOfDay === true) {
        expectedValue = moment.utc(date).endOf('day').valueOf();

      } else if (typeof date !== 'string') {
        expectedValue = moment.utc(date).valueOf();
      }

      return expectedValue;
    };

    const onChange = ({ value }) => {
      // Convert to the expected format or timestamp if no dateTimeFormat specified
      const expectedValue = mapToExpectedValue(value, dateTimeFormat);
      input.onChange(expectedValue);
      this.setState({ touched: true });
    };

    const resolvedLocale = locale || 'en';
    const dateFormat = getDateFormat(resolvedLocale);
    const translations = calendarTranslations[resolvedLocale];

    return (
      <div className="input-field input-calendar">
        {label && <label>{label}</label>}{required && <span> *</span>}
        <br />
        <DatePicker showIcon={true} icon="fa-calendar-o"
          value={input.value ? convertValueToDate(input.value) : null}
          onChange={onChange}
          dateFormat={dateFormat} locale={translations}
          readOnlyInput={true} showButtonBar={true} disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
        />
        {(input.value || touched) && error && (
          <Fragment>
            <div className='input-field-error'><FormattedMessage id={error} /></div>
          </Fragment>
        )}
      </div>
    );
  }

}
