import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { watchAPI } from "./api";

export const sagaMiddleware = createSagaMiddleware();

export const rootSaga = function* rootSaga() {
  yield all([
    watchAPI(),
  ]);
};
