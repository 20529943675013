import React from 'react';

import { FormattedMessage } from 'react-intl';
import { InputText } from 'primereact/components/inputtext/InputText';

export default ({ input, keyfilter, label, required, disabled, meta: { touched, error }, showError }) => (
  <div className="input-field input-text">
    {label && <label>{label}</label>}{required && <span> *</span>}
    <InputText {...input} keyfilter={keyfilter} autoComplete={input.name} disabled={disabled} />
    {touched && error && showError !== false && <div className="input-field-error"><FormattedMessage id={error} /></div>}
  </div>
);
