import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const mapRouteToBreadcrumb = (breadcrumb, breadcrumbIndex, breadcrumbsCount) => {

  const isNavigationLink = breadcrumbsCount - 1 !== breadcrumbIndex;

  const { match: { url }, children: label } = breadcrumb.props;

  if (label === undefined) {
    // Case when the breadcrumb is a custom react component
    return (
      <span key={breadcrumbIndex}>
        {isNavigationLink ? <NavLink to={url}>{breadcrumb}</NavLink> : <span className="title">{breadcrumb}</span>}
        {isNavigationLink && <span className="separator"> > </span>}
      </span>
    );

  } else {
    // Case when the breadcrumb is a string
    return (
      <span key={breadcrumbIndex}>
        {isNavigationLink ? <NavLink to={url}><FormattedMessage id={label} /></NavLink> : <span className="title"><FormattedMessage id={label} /></span>}
        {isNavigationLink && <span className="separator"> > </span>}
      </span>
    );
  }

};

const Breadcrumbs = ({ breadcrumbs }) => (
  <div className="breadcrumbs">
    {(breadcrumbs || []).map((breadcrumb, index) => mapRouteToBreadcrumb(breadcrumb, index, breadcrumbs.length))}
  </div>
);

// Map and render breadcrumbs
export default Breadcrumbs;