import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {connectModal} from 'redux-modal';
import {Dialog} from 'primereact/components/dialog/Dialog';
import { get } from 'lodash/fp';

import apiActions from '../../actions/api.actions';

import AddCarForm from '../forms/AddCarForm';

const { addCar } = apiActions;
export const ADD_CAR_MODAL = 'AddCarModal';

class AddCarModal extends Component {

  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.vin = null;
  }

  onCancel() {
    const {handleHide} = this.props;
    handleHide();
  }

  onSubmit = (car) => {
    const {addCar} = this.props;
    this.vin = car.vinNumber;
    addCar(car);
  };


  render() {
    const {
      show, intl: { formatMessage }, cars, runDiagnostics, formData
    } = this.props;
    let notification = null;

    if (get('error.response.status', cars) === 500) {
      notification = (
        <div className="ui-g-12 form-notification-invalid">
          <i className="fa fa-exclamation-triangle" aria-hidden="true" />
          <FormattedMessage id='MODALS.ADD_CAR.PROPEL_ERROR' />
        </div>
      );
    }

    return (
      <Dialog id="edit-infleet-modal" className="modal modal-sm" modal={true} width='640px' visible={show} onHide={this.onCancel} resizable={true} responsive={true} draggable={false} header={formatMessage({ id: 'MODALS.ADD_CAR.TITLE' })} >
        {notification}
        <div className="form-container">
        {cars.success &&
        <div className="blocker">
        <div className="infleeting-confirmation-container">
            <i className="fa fa-check fa-6" aria-hidden="true"></i>
            <h2><span>{formatMessage({ id: 'MODALS.ADD_CAR.CAR_ADDED' })}</span></h2>
            <button className="button button-submit" onClick={()=>runDiagnostics(this.vin)}><span>{formatMessage({ id: 'MODALS.ADD_CAR.RUN_DIAGNOSTIC' })}</span></button>
        </div>
        </div>
        }
          <AddCarForm onCancel={this.onCancel} onSubmit={this.onSubmit} formData={formData}/>
        </div>
      </Dialog>
    );
  }
}

AddCarModal = connectModal({
  name: ADD_CAR_MODAL,
  getModalState: state => state.modals
})(AddCarModal);

const mapStateToProps = state => ({
  cars: state.api.car||{}
});

const mapDispatchToProps = { addCar };

AddCarModal = connect(mapStateToProps, mapDispatchToProps)(AddCarModal);

export default injectIntl(AddCarModal);
