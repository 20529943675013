import axios from 'axios';

import { FILE_UPLOAD_API_URL, FILE_DOWNLOAD_API_URL } from '../configuration/app.configuration';

export function uploadFiles(files) {

  const data = new FormData();

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    data.append('fileContent[' + i + ']', file, file.name);
  }

  const config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  };

  return axios
    .post(FILE_UPLOAD_API_URL, data, config)
    .then(({ data }) => {
      console.debug(`File(s) ${files} upload successful`);
      return data;
    })
    .catch(error => {
      console.error(`File(s) ${files} upload failed with error ${error.message}`);
      return Promise.reject(error);
    });
}

export function getFileUrl(file) {
  return `${FILE_DOWNLOAD_API_URL}?fileLocation=${file.fileLocation}`;
}

export function downloadFile(file) {
  axios
    .get(getFileUrl(file), {
      responseType: 'blob'
    })
    .then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', file.fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(error => {
      console.error(`File ${file.fileLocation} download failed with error ${error.message}`);
      return Promise.reject(error);
    });
}
