import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { RadioButton } from 'primereact/components/radiobutton/RadioButton';

const Radio = ({ label, value, checked, onChange, disposition }) => (
  <div className={classNames("radio-option", { "vertical": disposition === 'vertical' })}>
    <label>
      <RadioButton value={value} onChange={onChange} checked={checked} />&nbsp;<FormattedMessage id={label} defaultMessage={label} />
    </label>
  </div>
);

export default class RadioGroup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      touched: false,
      value: this.props.meta.initial
    };
  }

  getOptionValue(option) {
    const { keyField } = this.props;
    return option[keyField || 'value'];
  }

  render() {
    const { input, label, required, currentValue, options, disposition, onOptionSelected, meta: { touched, error } } = this.props;

    const onChange = ({ value }) => {
      this.setState({ value, touched: true });

      input.onChange(value);

      typeof onOptionSelected === 'function' && onOptionSelected(value);
    };

    const mappedOptions = (options || [])
      .map((option, index) => {

        const optionValue = this.getOptionValue(option);

        return (
          <Radio
            key={index}
            type="radio"
            value={optionValue}
            label={option.label}
            disposition={disposition}
            onChange={onChange}
            checked={currentValue !== undefined ? optionValue === currentValue : optionValue === this.state.value} />
        );
      });

    return (
      <div className="input-field">
        {label && <label><FormattedMessage id={label} defaultMessage={label} /></label>}{required && <span> *</span>}
        <div>{mappedOptions}</div>
        {touched && error && <div className="input-field-error"><FormattedMessage id={error} /></div>}
      </div>
    );
  }
}
