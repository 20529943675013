
/**
 * NOTE: Please DO NOT configure any password or secrets in this file. 
 * Inject them into .env by build pipeline instead.
 */
import CONFIG from 'js/configuration/env';

export const GST_RATE = 0.05;

// default env to DEV
let ENVIRONMENT = "dev";
if (CONFIG.ENV === 'TST') {
  ENVIRONMENT = "tst";
} else if (CONFIG.ENV === "STG") {
  ENVIRONMENT = "stg";
} else if (CONFIG.ENV === "PRD") {
  ENVIRONMENT = "prd";
}

let amplifyConfig = {
  Auth: {

    // Amazon Cognito Region
    region: process.env.REACT_APP_COGNITO_REGION || 'us-west-2', 
    
    // Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID, 

    //Amazon Cognito Web Client ID
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID, 

    mandatorySignIn: true
  }
};

let SERVICE_URL_BASE = process.env.REACT_APP_API_BASE_URL || 'https://gateway.api.' + ENVIRONMENT + '.evo.ca';
let SERVICE_URL_MULE = SERVICE_URL_BASE + '/backoffice';
let SERVICE_MOCKUP_URL = 'http://localhost:9090';
let SERVICE_AIMA_BASE_URL = 'https://o6ser83h3j.execute-api.us-west-2.amazonaws.com/aimadev';
let VANCOUVER_PARKING_LOTS_BASE_URL = 'https://www.stage.evo.ca/';



if (CONFIG.ENV === 'DEV') {  
  amplifyConfig = {
    Auth: {
      region: 'us-west-2', 
      userPoolId: 'us-west-2_Q4W6c8anx', 
      userPoolWebClientId: '7fplkq2es5t2r26v21adu4ltf2',
      mandatorySignIn: true
    }
  };
} else if (CONFIG.ENV === 'PRD') {
  VANCOUVER_PARKING_LOTS_BASE_URL = 'https://evo.ca';
}

export const AMPLIFY_CONFIG = amplifyConfig;

// API URLs
export const TOKEN_GRANT_URL = `${SERVICE_URL_MULE}/authentication/token`;
export const TOKEN_VALIDATION_URL = `${SERVICE_URL_MULE}/authentication/validate`;
export const TOKEN_REVOKE_URL = `${SERVICE_URL_MULE}/authentication/revoke`;

export const ACTIVITY_LOG_API_URL = `${SERVICE_URL_MULE}/activity-log/getactivity/{id}`;

export const ALERT_API_URL = `${SERVICE_URL_MULE}/customer-process/alertNote/{id}`;
export const CUSTOMER_API_URL = `${SERVICE_URL_MULE}/customer-process/searchCustomer`;
export const PROMOTION_API_URL = `${SERVICE_URL_MULE}/promotions`;
export const TICKET_API_URL = `${SERVICE_URL_MULE}/tickets`;
export const FETCH__TICKET_API_URL = `${SERVICE_URL_MULE}/tickets/{id}`;
export const RELATED_CUSTOMERS_API_URL = `${SERVICE_URL_MULE}/tickets/{id}/customers`;
export const PAYMENT_API_URL = `${SERVICE_URL_MULE}/payments`;

export const BILLING_API_URL = `${SERVICE_URL_MULE}/customers-billing/payments`;
export const BILLING_SUMMARY_API_URL = `${SERVICE_URL_MULE}/customers-billing/balances`;
export const BILLING_DETAIL_API_URL = `${SERVICE_URL_MULE}/customers-billing/paymentdetails`;
export const BILLING_PROCESS_PAYMENT_API_URL = `${SERVICE_URL_MULE}/bill/{id}`;
export const BILLING_FEE_API_URL = `${SERVICE_URL_MULE}/customers-billing/productfeesdetails`;
export const BILLING_NET_FUEL_REIMBURSEMENT_API_URL = `${SERVICE_URL_MULE}/customers-billing/netfuelreimbursement`;
export const BILLING_MONTHLY_STATEMENTS_API_URL = `${SERVICE_URL_MULE}/customers-billing/monthlyStatementList`;
export const BILLING_GENERATE_MONTHLY_STATEMENT_API_URL = `${SERVICE_URL_MULE}/customers-billing/genMonthlyStatement`;

export const FREE_MINUTE_API_URL = `${SERVICE_URL_MULE}/customer-freeminute`;
export const FREE_MINUTE_SUMMARY_API_URL = `${SERVICE_URL_MULE}/customer-freeminute/summary`;

export const CUSTOMER_DETAIL_API_URL = `${SERVICE_URL_MULE}/customer/{id}`;
export const FETCH__RFID_CONFLICT_API_URL = `${SERVICE_URL_MULE}/customer`;

export const ACCOUNT_SUMMARY_API_URL = `${SERVICE_URL_MULE}/customer-process/accountSummary/{id}`;
export const PERSONAL_INFORMATION_API_URL = `${SERVICE_URL_MULE}/customer-process/personalInformation/{id}`;
export const MEMBERSHIP_API_URL = `${SERVICE_URL_MULE}/customer-process/membership/{id}`;
export const DRIVER_LICENCE_API_URL = `${SERVICE_URL_MULE}/customer-process/driverLicence/{id}`;
export const PROFILE_NOTES_API_URL = `${SERVICE_URL_MULE}/customer-process/profileNotes/{id}`;
export const UPDATE__PROFILE_NOTES_API_URL = `${SERVICE_URL_MULE}/customer-process/profileNotes/{id}/{noteId}`;
export const DELETE__PROFILE_NOTES_API_URL = `${SERVICE_URL_MULE}/customer-process/profileNotes/{id}/{noteId}`;
export const PAYMENT_INFORMATION_API_URL = `${SERVICE_URL_MULE}/customer-process/paymentInformation/{id}`;
export const SCHOOL_API_URL = `${SERVICE_URL_MULE}/customer-process/school/{id}`;
export const RESERVATION_API_URL = `${SERVICE_URL_BASE}/vehicle-service/reservation`;
export const AVAILABLE_VEHICLES_API_URL = `${SERVICE_URL_BASE}/vehicle-service/internal/reservation/vehicle/available`;
export const RESERVATION_GEOCODE_API_URL = `https://api.mapbox.com/geocoding/v5/mapbox.places/{id}.json`;

 
export const MEMBERSHIP_VALIDATION_API_URL = `${SERVICE_URL_MULE}/membership`;
export const NOTES_API_URL = `${SERVICE_URL_MULE}/notes`;
export const STAFF_API_URL = `${SERVICE_URL_MULE}/staffs`;
export const CURRENT_STAFF_API_URL = `${SERVICE_URL_MULE}/currentStaff`;
export const SPECIAL_FEE_API_URL = `${SERVICE_URL_MULE}/transaction/gen-special-fee-trans`;
export const FEE_REVERSAL_API_URL = `${SERVICE_URL_MULE}/transaction/gen-special-fee-reversal`;
export const PROMOTIONS_API_URL = `${SERVICE_URL_MULE}/promotions`;
export const GVALIDATE__PROMOTION_API_URL = `${SERVICE_URL_MULE}/promotions/promocode/{id}`;
export const FREE_MINUTES_SUMMARY_API_URL = `${SERVICE_URL_MULE}/customer-freeminute`;
export const FREE_MINUTES_API_URL = `${SERVICE_URL_MULE}/customer-freeminute/summary`;
export const FREE_MINUTES_APPLY_PROMO = `${SERVICE_URL_MULE}/apply-promo`;

export const REGIONS_API_URL = `${SERVICE_URL_MULE}/regions`;
export const SUB_CHANNELS_API_URL = `${SERVICE_URL_MULE}/subChannels`;

export const FILE_DOWNLOAD_API_URL = `${SERVICE_URL_MULE}/files`;
export const FILE_UPLOAD_API_URL = `${SERVICE_URL_MULE}/files/upload`;

export const MASS_IMPORT_FREE_MINUTE_API_URL = `${SERVICE_URL_MULE}/freeminutes-massimport`;
export const MASS_IMPORT_APPLY_PROMO_CODE_API_URL = `${SERVICE_URL_MULE}/applypromo-massimport`;
export const MASS_IMPORT_PROMOTION_API_URL = `${SERVICE_URL_MULE}/promotions-massimport`;
export const MASS_IMPORT_API_URL = `${SERVICE_URL_MULE}/mass-imports`;

export const APPLY_PROMO_API_URL = `${SERVICE_URL_MULE}/apply-promo`;
export const CUSTOMERS_API_URL = `${SERVICE_URL_MULE}/customer`;

export const INFLEET_API_URL = `${SERVICE_URL_BASE}/vehicle-service/infleet/validate`;
export const CAR_API_URL = `${SERVICE_URL_BASE}/vehicle-service/infleet`;
export const CARS_API_URL = `${SERVICE_URL_BASE}/vehicle-service/vehicle/search`;
export const CARS_MSISDN_API_URL = `${SERVICE_URL_BASE}/vehicle-service/internal/vehicle/search/msisdn/`;
export const CAR_DETAIL_API_URL = `${SERVICE_URL_BASE}/vehicle-service/vehicle/{id}`;
export const CAR_WAKEUP_API_URL = `${SERVICE_URL_BASE}/vehicle-service/vehicle-operations/wakeup/{id}`;
export const CAR_UPDATE_STATUS_API_URL = `${SERVICE_URL_BASE}/vehicle-service/internal/vehicle/{vin}/status/{current_status}/{next_status}`;
export const CAR_STATE_API_URL = `${SERVICE_URL_BASE}/vehicle-service/telemetry/{id}/latest`;
export const TRIP_BY_CAR_API_URL = `${SERVICE_URL_BASE}/vehicle-service/internal/trip/vehicle/{id}`;
export const TICKET_BY_CAR_API_URL = `${SERVICE_URL_MULE}/tickets/{id}/vehicle`;
export const INFLEETFORM_API_URL = `${SERVICE_URL_BASE}/vehicle-service/infleet/formFields`;
export const PENDING_VEHICLES_API_URL = `${SERVICE_URL_BASE}/vehicle-service/infleet/pendingVehicles`;
export const DIAGNOSTIC_API_URL = `${SERVICE_URL_BASE}/vehicle-service/vehicle-operations/runDiagnostics`;

export const FLEETSERVICE_API_URL = `${SERVICE_URL_BASE}/fleet-service/internal/fleets`;
export const FLEETSERVICEZONES_API_URL = `${SERVICE_URL_BASE}/fleet-service/internal/fleets/zones`;
export const HOME_ZONE_ACTIVATION_API_URL = `${SERVICE_URL_BASE}/fleet-service/internal/fleets/{id2}/zones/{id}`;
export const HOME_ZONE_AUDIT_API_URL = `${SERVICE_URL_BASE}/fleet-service/internal/fleets/zones/{id}/audit`;

export const TRIP_INFO_API_URL = `${SERVICE_URL_BASE}/vehicle-service/internal/trip/{id}`;
export const TRIP_DETAIL_API_URL = `${SERVICE_URL_MULE}/customers-billing/transactiondetails`;
export const TRIP_GEOCODE_API_URL = `${SERVICE_MOCKUP_URL}/trips-geocode`;
export const TRIP_ROUTE_API_URL = `${SERVICE_URL_BASE}/vehicle-service/internal/trip/directions`;
export const TRIP_API_URL = `${SERVICE_URL_MULE}/trips`;
export const TRIP_BY_CUSTOMER_API_URL = `${SERVICE_URL_BASE}/vehicle-service/internal/trip/customer/{id}`;
export const ACTIVE_TRIP_LIST_API_URL = `${SERVICE_URL_BASE}/vehicle-service/internal/trip/active`;
export const TRIP_DETAIL_LIST_API_URL = `${SERVICE_URL_BASE}/vehicle-service/internal/trip/telemetry/{id}`;
export const TRIP_ADJ_API_URL = `${SERVICE_URL_MULE}/transaction/gen-trip-adj`;
export const PARKING_SPOT_LIST_API_URL = `${SERVICE_URL_BASE}/fleet-service/parking/spots`;
export const TRIP_DETAIL_TROUBLE_SHOOT_API_URL = `${SERVICE_URL_BASE}/vehicle-service/vehicle-operations/{cmd}/{id}`;
export const END_TRIP_API_URL = `${SERVICE_URL_BASE}/vehicle-service/internal/trip/{id}/{cmd}`;
export const PARKING_TICKET_API_URL = `${SERVICE_URL_BASE}/vehicle-service/internal/trip/completed/{date}/vehicle/{id}/`;

export const INFLEETING_RUN_DIAGNOSTICS_API_URL = `${SERVICE_URL_BASE}/vehicle-service/infleet/validate`;
export const INFLEETING_RE_TEST_API_URL = `${SERVICE_URL_BASE}/vehicle-service/vehicle-operations/runDiagnostics`;
export const INFLEETING_DEPLOY_TO_FLEET_API_URL = `${SERVICE_URL_BASE}/vehicle-service/infleet/promote/{id}`;
export const INFLEETING_VALIDATE_API_URL = `${SERVICE_URL_BASE}/vehicle-service/infleet/vendor`;
export const INFLEETING_DIAGNOSTICS_RESULT_API_URL = `${SERVICE_URL_BASE}/vehicle-service/vehicle-operations/diagnosticResults/{id}`;
export const INFLEETING_DIAGNOSTIC_COMMANDS_API_URL = `${SERVICE_URL_BASE}/vehicle-service/vehicle-operations/{id}`;
export const CAR_UPDATE_VALIDATE_API_URL = `${SERVICE_URL_BASE}/vehicle-service/infleet/validate?update=true`;
export const CAR_HISTORY_API_URL = `${SERVICE_URL_BASE}/vehicle-service/internal/vehicle/{id}/history`;
export const CAR_DATA_API_URL = `${SERVICE_URL_BASE}/vehicle-service/internal/vehicle/{vin}/data`;

// Exported data from https://opendata.vancouver.ca/explore/dataset/parking-meters/information, hosted on S3 bucket
export const VANCOUVER_PARKING_METERS_API_URL = 'https://backoffice.test.evo.ca/parking/parking-meters.json';

export const VANCOUVER_PARKING_LOTS_API_URL = `${VANCOUVER_PARKING_LOTS_BASE_URL}/API/ParkingSpotsApp.aspx`;

// aima parking urls
export const AIMA_PARKING_API_URL = `${SERVICE_AIMA_BASE_URL}/parking`;
export const AIMA_UPDATE_PARKING_API_URL = `${SERVICE_AIMA_BASE_URL}/parking/update`;
export const AIMA_ADD_PARKING_API_URL = `${SERVICE_AIMA_BASE_URL}/parking/add`;

// switch provider:
export const PROVIDER_API_URL = `${SERVICE_URL_BASE}/fleet-service/internal/fleets/wakeup/provider`;
export const SWITCH_PROVIDER_API_URL = `${SERVICE_URL_BASE}/fleet-service/internal/fleets/{id}/wakeup/provider/{cmd}`;

export const SWITCH_PROVIDER_FAILURE_API_URL = `${SERVICE_URL_BASE}/fleet-service/internal/fleets/wakeup/provider/failures`;

export const SUPPRESS_AUTHORIZATION_URLS = [VANCOUVER_PARKING_LOTS_API_URL];
