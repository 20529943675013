import { merge } from 'lodash/fp';
import { constArraytoObj, constArraytoActionObj } from '../utils/action.utils';

const actions = [
  'ENABLE_SPLIT_SCREEN',
  'DISABLE_SPLIT_SCREEN',
  'TOGGLE_SPLIT_SCREEN',
];

export default merge(constArraytoObj(actions), constArraytoActionObj(actions));
