import React from 'react';
import { compose } from 'recompose';
import classNames from 'classnames';
import Select from 'react-select';
import Highlighter from 'react-highlight-words';
import { withState } from 'js/utils/with';
import 'react-select/dist/react-select.css';
import { isArray } from 'lodash/fp';

const TypeAhead = ({ onChange, name, value, clearable, error, placeholder, multiple, loadOptions, setInputValue, className }) =>
  <Select.Async
    id={name}
    name={name}
    className={className + classNames(error ? ' input-error' : false)}
    cache={false}
    clearable={clearable || false}
    clearValueText=''
    arrowRenderer={() => <span className="input-typeahead-dropdown-icon fa fa-fw fa-caret-down ui-clickable" />}
    multi={multiple || false}
    value={value}
    placeholder={placeholder || ''}
    onChange={v => onChange(null, null, v)}
    loadOptions={loadOptions}
    filterOptions={(options, _, currentValues) => options.filter(o => (currentValues ? (isArray(currentValues) ? currentValues : [currentValues]) : []).map(x => x.label.toLowerCase()).indexOf(o.label.toLowerCase()) === -1)}
    onInputChange={setInputValue}
    optionRenderer={(option, inputValue) => <Highlighter className='highlighted-value' searchWords={[inputValue.toString()]} textToHighlight={option.label} />}
  />;

export default compose(
  withState('inputValue')
)(TypeAhead);
