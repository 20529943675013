import React, { Component } from "react";

import { FormattedMessage } from "react-intl";
import { Checkbox } from "primereact/components/checkbox/Checkbox";

export default class extends Component {
  onChange = event => {
    const { input, meta: { initial }} = this.props;
    let { checked } = event;

    checked = typeof checked === 'undefined' ? this.state ? !this.state.checked : !initial : checked;
    this.setState({ checked });
    input.onChange(checked);
  };

  render() {
    const { input, label, required, meta: { touched, error, initial } } = this.props;

    return (
      <div className="input-field input-checkbox">
        <Checkbox value={input.value} onChange={this.onChange} checked={this.state ? this.state.checked : initial} />
        {label && <label onClick={this.onChange}>{label}</label>}{required && <span> *</span>}
        {touched && error && (
          <div className="input-field-error">
            <FormattedMessage id={error} />
          </div>
        )}
      </div>
    );
  }

}

