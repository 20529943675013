import React, {Fragment} from 'react';
import GenericErrorMessage from './GenericErrorMessage';
import LoadingSpinner from './LoadingSpinner';

export default (p) =>
  p.error ? (p.errorContent || <GenericErrorMessage />) :
    p.dirty === null ?
      <SpinnerDiv {...p} /> :
      <div data-loading={p.isLoading} className={p.className + (p.dirty ? ' change-indicator' : '')}>
        <SpinnerDiv {...p} />
      </div>;


const SpinnerDiv = ({isLoading, showChildrenWhileLoading, children}) =>
  <Fragment>
    {isLoading ? <LoadingSpinner /> : null}
    {(showChildrenWhileLoading||!isLoading) ? children : null}
  </Fragment>;
