import { isObject, isEmpty, cloneDeep, fromPairs, pick, pickBy, omit } from 'lodash/fp';
import { toNumberType } from 'js/utils';

export const addTicket = ticket => {

  const ticketData = cloneDeep(ticket);

  // Delete ticket meta data
  delete ticketData['createDateTime'];
  delete ticketData['createdBy'];
  delete ticketData['updateDateTime'];
  delete ticketData['updatedBy'];

  if (!isEmpty(ticketData.notes)) {

    // Filter notes having IDs since BE will duplicate these if sent in the requset
    ticketData.notes = ticketData.notes.filter(note => note.id === undefined);

    // Delete notes meta data
    for (let note of ticketData.notes) {
      delete note['createDateTime'];
      delete note['createdBy'];
    }
  }

  // Map Categories / Customers / Vehicles / Staff to corresponding IDs
  if (ticketData.categories) {
    ticketData.categories = ticketData.categories.map(category => isObject(category) ? category.value : category);
  }

  if (ticket.customers) {
    ticketData.customers = ticketData.customers.map(customer => customer.id || customer.value || customer);
  }

  if (ticketData.vehicles) {
    ticketData.vehicles = ticketData.vehicles.map(vehicle => vehicle.id || vehicle.value || vehicle);
  }

  if (ticket.staff) {
    ticketData.staff = typeof ticketData.staff === 'object' ? ticketData.staff.id : ticketData.staff;
  }

  // Process attached files. If no attachment selected, send an empty files field
  if (!isEmpty(ticketData.files)) {
    ticketData.files = ticketData.files
      //Pick only relevant fields
      .map(uploadedFile => pick(['id', 'fileName', 'fileLocation', 'fileDescription'], uploadedFile))
      // Set to empty null file descriptions
      .map(uploadedFile => ({ ...uploadedFile, fileDescription: uploadedFile.fileDescription || '' }));
  }

  return ticketData;
};

export const updateTicket = addTicket;

export const searchTicket = data => {
  if (isEmpty(data.categories)) delete data.categories;
  if (!data.from) delete data.from;
  if (!data.to) delete data.to;
  return data;
};

export const searchPromotion = (params) => (pickBy((val) => (Array.isArray(val) ? !isEmpty(val) : !!val), params));

const DecoratePromotion = type => promotion => {
  const promotionData = fromPairs(Object.keys(promotion).map(k => [k, toNumberType(promotion[k])]));

  // Handle Regions:
  promotionData.regions && promotionData.regions.length && (promotionData.regions = promotionData.regions.map(r => type === 'update' ? r.id : r.name));

  // Handle Number to String:
  promotionData.description && (promotionData.description = promotionData.description.toString());
  promotionData.promoCode && (promotionData.promoCode = promotionData.promoCode.toString());
  promotionData.marketingNotes && (promotionData.marketingNotes = promotionData.marketingNotes.toString());

  return promotionData;
};

export const addPromotion = DecoratePromotion('add');
export const updatePromotion = DecoratePromotion('update');

export const addFreeMinute = freeMinutes => {
  const freeMinutesData = fromPairs(Object.keys(freeMinutes).map(k => [k, toNumberType(freeMinutes[k])]));

  freeMinutesData.regions && freeMinutesData.regions.length && (freeMinutesData.regions = freeMinutesData.regions.map(r => r.id));

  //delete freeMinutesData['expiry'];

  return freeMinutesData;
};

export const updateCustomerDetail = data => ({
  ...omit(['caaMemberShipInformation', 'emailConsentFlag', 'smsConsentFlag', 'accountInformation'], data),
  caaMembership: data.caaMemberShipInformation,
  isEmailConsent: data.emailConsentFlag,
  isSMSConsent: data.smsConsentFlag,
  accountSuspendStatus: data.accountInformation && data.accountInformation.accountSuspendStatus,
  accountStatus: data.accountInformation && data.accountInformation.accountStatus,
});

export const fetchCars = data => {
  return {...data, msisdn: data.msisdn&&data.msisdn[0].msisdn};
};
