import { push } from 'react-router-redux';
import { createAction } from 'redux-actions';
import { Auth } from 'aws-amplify';
import { get } from 'lodash/fp';

export const AUTHENTICATION_PENDING = 'AUTHENTICATION_PENDING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const SESSION_TIMEOUT = 'SESSION_TIMEOUT';

const getUsername = user => get('signInUserSession.idToken.payload.email', user) || user.username;
const getUserRoles = user => get('signInUserSession.idToken.payload.cognito:groups', user) || [];

export function getAuthenticatedUser() {
  return dispatch => {
    dispatch(authenticationPending());

    return Auth.currentAuthenticatedUser()
      .then((user) => {
        dispatch(loginSuccess({
          username: getUsername(user),
          userRoles: getUserRoles(user)
        }));
      })
      .catch(error => {
        console.error(error);
        dispatch(logout(true));
      });
  };
}

export function login(credentials, redirectUrl) {
  return dispatch => {
    dispatch(authenticationPending());

    const { username, password } = credentials;

    return Auth.signIn(username.toLowerCase(), password)
      .then(async (user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          const signInUserSession = await new Promise(function(resolve, reject) {
            user.completeNewPasswordChallenge(password, user.challengeParam.requiredAttributes, {
              onSuccess: resolve,
              onFailure: reject
            });
          });
          return { ...user, signInUserSession };
        } else {
          return user;
        }
      })
      .then((user) => {
        dispatch(loginSuccess({
          username: getUsername(user),
          userRoles: getUserRoles(user)
        }));
      })
      .then(() => {
        // Call login success callback
        dispatch(push(redirectUrl));
      })
      .catch(error => {
        console.error(error);
        dispatch(loginFailure(error));
      });
  };
}

export function logout(noCleanup = false) {
  return dispatch => {
    const cleanUp = () => {
      dispatch(push('/login'));
    };

    return Auth.signOut()
      .then(() => {
        dispatch(logoutSuccess());
        !noCleanup && cleanUp();
      })
      .catch(error => {
        console.error(error);
        dispatch(logoutFailure(error));
        !noCleanup && cleanUp();
      });
  };
}

/*
export function resetPassword(email) {
  // TODO complete password recovery workflow
  return dispatch => {

  };
}

export function setNewPassword(password) {
  // TODO complete password reset workflow
  return dispatch => {

  };
}
*/

export const authenticationPending = createAction(AUTHENTICATION_PENDING);

export const loginSuccess = createAction(LOGIN_SUCCESS, currentUser => ({ currentUser }));

export const loginFailure = createAction(LOGIN_FAILURE);

export const logoutSuccess = createAction(LOGOUT_SUCCESS);

export const logoutFailure = createAction(LOGOUT_FAILURE);
