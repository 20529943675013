import { reducer as formReducer } from 'redux-form';
import { reducer as modalReducer } from 'redux-modal';
import localizationReducer from './localization.reducer';
import routingReducer from './routing.reducer';
import authenticationReducer from './authentication.reducer';
import apiReducer from './api.reducer';
import SplitScreenReducer from './splitscreen.reducer';
import backendErrorReducer from './backend-errors.reducer';

const reducers = {
  routing: routingReducer,
  form: formReducer,
  modals: modalReducer,
  intl: localizationReducer,
  authentication: authenticationReducer,
  api: apiReducer,
  screenLayout: SplitScreenReducer,
  backendError: backendErrorReducer
};

export default reducers;
