import { createStore, combineReducers, applyMiddleware } from 'redux';
import { syncHistoryWithStore, routerMiddleware } from 'react-router-redux';
import { createHashHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { sagaMiddleware, rootSaga } from './saga/core.js';
import reducers from './reducers';
import ENV from 'js/configuration/env';

export const history = createHashHistory();

const routingMiddleware = routerMiddleware(history);

const middlewares = [
  routingMiddleware,
  thunkMiddleware,
  sagaMiddleware
];

let appliedMiddlewares = applyMiddleware(...middlewares);

if (ENV.IS_DEVELOPMENT) {
  appliedMiddlewares = composeWithDevTools(appliedMiddlewares);
}

const store = createStore(
  combineReducers(reducers),
  appliedMiddlewares
);

sagaMiddleware.run(rootSaga);

syncHistoryWithStore(history, store, { selectLocationState: state => state.routing });

export default store;
