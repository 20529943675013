import React from 'react';
import { compose, withProps } from 'recompose';
import { find } from 'lodash/fp';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { Dialog } from 'primereact/components/dialog/Dialog';
import { InputText } from 'primereact/components/inputtext/InputText';
import { TabView, TabPanel } from 'primereact/components/tabview/TabView';
import { ScrollPanel } from 'primereact/components/scrollpanel/ScrollPanel';
import { withModal } from '../elements/ModalControls';
import { withState, withReceiveProps } from 'js/utils/with';
import ticketGroups from 'js/constants/ticket-groups';
import { escapeSpecialCharacters } from 'js/utils/formatting.utils';

export const TICKET_CATEGORIES_MODAL_NAME = 'TicketCategoriesModal';

const TicketCategoriesModal = ({ modalValue, hideModal, intl: { formatMessage }, mappedGroups, mappedSelectedCategories, filter, setFilter, selectedCategories, done }) =>
  <Dialog id="ticket-categories-modal" className="modal modal-sm" header={formatMessage({ id: 'TICKET_SEARCH.TICKET_CATEGORIES' })}
    modal={true} width="1200px" visible={modalValue.visible} onHide={() => hideModal()}>

    <div className="ticket-categories ui-g-12">
      <div className="ui-g-8">
        {mappedGroups.length > 0 ?
          <TabView id="ticket-groups-tabs">{mappedGroups}</TabView> :
          <FormattedMessage id='TICKET_SEARCH.NO_MATCH_FOUND' />
        }
      </div>

      <div className="ui-g-4 categories-outer">
        <div className="ui-g-12 categories-filter">
          <InputText value={filter} onChange={e => setFilter(e.target.value)} placeholder={formatMessage({ id: 'TICKET_SEARCH.FILTER_CATEGORIES' })} />
        </div>

        <div className="selected-ticket-categories ui-g-12">
          <h4><FormattedMessage id='TICKET_SEARCH.TOTAL_SELECTED' /></h4>
          <hr />

          <ScrollPanel style={{ height: '25rem', 'background': 'none', 'border': 'none' }}>
            <ul>
              {mappedSelectedCategories}
            </ul>
          </ScrollPanel>
        </div>
      </div>
    </div>

    <div className="ui-g-12 buttons-container ui-dialog-footer">
      <button className="button button-submit" type="submit" onClick={() => {done(selectedCategories);hideModal();}}>
        <FormattedMessage id='GLOBAL.DONE' />
      </button>

      <button className="button button-reset" type="button" onClick={() => hideModal()}>
        <FormattedMessage id='GLOBAL.CANCEL' />
      </button>
    </div>

  </Dialog>;

export default compose(
  withModal(TICKET_CATEGORIES_MODAL_NAME),
  withState('filter'),
  withState('selectedCategories', []),
  withState('toggledCategory', { subcategories: [] }),
  withReceiveProps((p1, p2) => !p1.modalValue.visible && p2.modalValue.visible && p2.setSelectedCategories(p2.initialValues || [])),
  withProps(({ selectedCategories, setSelectedCategories, filter }) => ({
    filterSubcategory: c => c.label.toLowerCase().includes(escapeSpecialCharacters(filter).toLowerCase()),
    toggleValue: category => setSelectedCategories(
      find(c => c.label === category.label, selectedCategories)
        ? selectedCategories.filter(c => c.label !== category.label)
        : [...selectedCategories, category]
    )
  })),
  withProps(({ selectedCategories, toggledCategory, setToggledCategory, filterSubcategory, toggleValue }) => ({
    mappedCategories: group => group.categories
      .filter(c => c.subcategories.some(filterSubcategory))
      .map((c, i) =>
        <li key={i} className={classNames('group link', { 'active': toggledCategory.label === c.label })} onClick={() => setToggledCategory(c)}>
          {c.label}
        </li>
      ),
    mappedSubcategories: toggledCategory.subcategories
      .filter(filterSubcategory)
      .map((c, i) =>
        <li key={i} className="subcategory" onClick={() => toggleValue(c)}>
          <span className={classNames({ 'selected': selectedCategories.includes(c) })} >{c.label}</span>
        </li>
      ),
    mappedSelectedCategories: selectedCategories.map((c, i) =>
      <li key={i} className="selected-category link" onClick={() => toggleValue(c)}>
        <i className={c.icon} />
        <span>{c.label}</span>
        <i className="fa fa-times" />
      </li>
    )
  })),
  withProps(({ mappedCategories, mappedSubcategories, filterSubcategory }) => ({
    mappedGroups: ticketGroups
      .filter(g => g.categories.some(c => c.subcategories.some(filterSubcategory)))
      .map((g, i) =>
        <TabPanel leftIcon={g.icon} header={` ${g.label}`} key={i}>
          <div className="ui-g-12 categories-listing" key={i}>
            <ul className="ui-g-4">
              {mappedCategories(g)}
            </ul>
            <ul className="ui-g-8">
              {mappedSubcategories}
            </ul>
          </div>
        </TabPanel>
      )
  })),
  injectIntl
)(TicketCategoriesModal);
