import actions from "../actions/backend-errors.actions";
const {
  UPDATE_BACKEND_ERROR,
  CLEAR_BACKEND_ERROR
} = actions;

const INITIAL_STATE = {
  message: null
};

const BackendErrorReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case UPDATE_BACKEND_ERROR:
      return { message: action.payload };

    case CLEAR_BACKEND_ERROR:
      return { message: null };

    default:
      return state;
  }
};

export default BackendErrorReducer;
