import splitScreenActions from "../actions/splitscreen.actions";
const { ENABLE_SPLIT_SCREEN, DISABLE_SPLIT_SCREEN, TOGGLE_SPLIT_SCREEN } = splitScreenActions;

const INITIAL_STATE = {
  allowSplitScreen: false,
  splitScreenIsOpen: false
};

const splitScreenReducer = (state = INITIAL_STATE, action = {}) => {

  switch (action.type) {

    case ENABLE_SPLIT_SCREEN:
      return { ...state, allowSplitScreen: true };

    case DISABLE_SPLIT_SCREEN:
      return { ...state, allowSplitScreen: false };

    case TOGGLE_SPLIT_SCREEN:
      return { ...state, splitScreenIsOpen: !state.splitScreenIsOpen };

    default:
      return state;
  }
};

export default splitScreenReducer;
