import { map, flatMap, reverse, groupBy, pick } from 'lodash';
import ticketSubcategories from './ticket-subcategories';

function getGroupIcon(group) {
  switch (group) {
    case 'Fleet':
      return 'fa fa-car';
    case 'Member Services':
      return 'fa fa-user';
    default:
      console.log(`Ticket group [${group}] has no assigned icon`);
      break;
  }
}

const ticketGroups = reverse(flatMap(
  groupBy(ticketSubcategories, 'group'),
  (group, key) => ({
    label: key,
    icon: getGroupIcon(key),
    categories: flatMap(
      groupBy(map(group, category => pick(category, ['value', 'category', 'label', 'icon'])), 'category'),
      (category, key) => ({
        label: key,
        subcategories: map(category, subcategory => pick(subcategory, ['value', 'label', 'icon']))
      }))
  })));

export default ticketGroups;
