
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { isEmpty } from 'lodash';
import { Field } from 'redux-form';
import { FormattedMessage, FormattedDate } from 'react-intl';

import { uploadFiles, downloadFile, getFileUrl } from '../../../services/file-storage.service';

import LoadingSpinner from '../../elements/LoadingSpinner';
import InputText from './InputText';
import moment from 'moment-timezone';

export default class FileUpload extends Component {

  constructor(props) {
    super(props);

    this.state = {
      uploadInProgress: false,
      files: this.props.meta.initial || []
    };
  }

  render() {
    const { input: { value: files, name, onChange }, label, disabled, showConfirmationModal } = this.props;

    const { uploadInProgress, rejectedFiles } = this.state;

    const onDrop = (acceptedFiles, rejectedFiles) => {

      if (!isEmpty(rejectedFiles)) {
        // Display error message and highlight rejected files names and extensions
        this.setState({ rejectedFiles });

      } else {
        this.setState({ rejectedFiles: [] });
      }

      // Update or empty attachments
      if (!isEmpty(acceptedFiles)) {

        this.setState({ uploadInProgress: true });

        uploadFiles(acceptedFiles)
          .then(uploadedFiles => {
            const updatedFiles = this.state.files.concat(uploadedFiles);

            this.setState({ files: updatedFiles, uploadInProgress: false });
            onChange(updatedFiles);
          })
          .catch(() => {

            this.setState({ uploadInProgress: false });
          });
      }
    };

    const removeFile = (fileToDelete) => {
      const filteredfiles = files.filter(file => file.fileName !== fileToDelete.fileName);

      this.setState({ files: filteredfiles });
      onChange(filteredfiles);
    };

    return (
      <div className="input-field input-fileupload">

        {label && <p><FormattedMessage id={label} /></p>}

        {!isEmpty(rejectedFiles) && (
          <div>
            <div className="input-field-error"><FormattedMessage id='FILE_UPLOAD.REJECTED_FILES_MESSAGE' values={{fileName: rejectedFiles[0].name}} /></div>
            {rejectedFiles.map((rejectedFile, index) => (
              <p key={index} className="input-field-error">
                <b>{rejectedFile.name}</b>
                &nbsp;
                <span>{`(${rejectedFile.type} - ${Math.round(rejectedFile.size / 1024)} KB)`}</span>
              </p>
            ))}
          </div>
        )}

        <div className="file-upload-container">

            <Dropzone className="ui-file-upload" multiple={true} disabled={disabled || false} onDrop={onDrop} maxSize={1024 * 1024}
              accept="image/jpeg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document">

              <div className="field-title">

                <span className="drag-and-drop">
                  <FormattedMessage id='FILE_UPLOAD.DRAG_AND_DROP' />
                </span>

                &nbsp;

              <span className="browse-files">
                  <FormattedMessage id='FILE_UPLOAD.BROWSE_FILES' />
                </span>

                {uploadInProgress && <span className="spinner-inline"><LoadingSpinner /></span>}

              </div>
            </Dropzone>

            {!isEmpty(files) && (
              <div className="summary-table-container">
                <table className="summary-table striped">
                  <thead>
                    <tr>
                      <th><FormattedMessage id='FILE_UPLOAD.FILE_NAME' /></th>
                      <th><FormattedMessage id='FILE_UPLOAD.DATE_TIME_ADDED' /></th>
                      <th><FormattedMessage id='FILE_UPLOAD.DESCRIPTION' /></th>
                      <th>
                        &nbsp;
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(files || []).map((file, index) => (
                      <tr key={index}>
                        <td>
                          <span className="link" data-url={getFileUrl(file)} onClick={() => downloadFile(file)}>
                            {file.fileName}
                          </span>
                        </td>
                        <td>
                          <FormattedDate value={file.createDateTime} year='numeric' month='short' day='2-digit' />{' - '}
                          {
                                  <span id={'file'+index} className='value'>
                                    {moment.tz(file.createDateTime,  moment.tz.guess()).format('h:mm a')} {(moment.tz(file.createDateTime,  moment.tz.guess())).zoneAbbr()}
                                  </span>
                          }
                        </td>
                        <td className="with-field-helper">
                          <Field type="input" component={InputText} name={`${name}[${index}].fileDescription`} />
                          <span className="field-helper">
                            <i className="fa fa-pencil" />
                          </span>
                        </td>
                        <td>
                          {/* <span className="link text-bold text-uppercase" onClick={() => removeFile(file)}> */}
                          <span className="link text-bold text-uppercase" onClick={() => showConfirmationModal({ value: file.fileName, positiveCallback: () => removeFile(file)})}>
                            &nbsp;
                            <i className="fa fa-trash" />
                            &nbsp;
                          <FormattedMessage id='FILE_UPLOAD.REMOVE' />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

        </div>

        </div>
        );

      }

    }
