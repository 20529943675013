import { connect } from 'react-redux';
import { get, flatten, isArray } from 'lodash/fp';
import { ALLOWED_ROUTES } from 'js/constants/rbac-policies';

const ROOT_VIEW = "/";

const canCurrentUserAccessRoute = (userRoles, uri) =>
  isArray(uri) ?  uri.filter(u => flatten(userRoles
    .map(r => r.trim())
    .map(r => r.toUpperCase())
    .map(r => ALLOWED_ROUTES[r])).includes(u)).length > 0 :
  flatten(userRoles
    .map(r => r.trim())
    .map(r => r.toUpperCase())
    .map(r => ALLOWED_ROUTES[r]))
    .filter(u => uri === u).length > 0;

const RoleBasedAccess = ({ children, placeholder, uri, userRoles }) => {
  let pc = placeholder || '';
  return (uri === ROOT_VIEW || canCurrentUserAccessRoute(userRoles, uri)) ? children : pc;
};

const mapStateToProps = state => ({
  userRoles: get('authentication.currentUser.userRoles', state) || []
});

export default connect(mapStateToProps)(RoleBasedAccess);
