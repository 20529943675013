import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { hideHeaderForRoute } from '../../routes';
import splitScreenActions from "../../actions/splitscreen.actions";
import { loadEnglish, loadFrench } from "../../actions/localization.actions";
import { ENGLISH } from "../../constants/languages";
import Authentication from './Authentication';
import RoleBasedAccess from '../rbac/RoleBasedAccess';
const { toggleSplitScreen, splitScreenIsOpen } = splitScreenActions;

let RootNavigation = (props) => {

  const { locale, loadEnglish, loadFrench, toggleSplitScreen, location, routing: { locationBeforeTransition, locationAfterTransition } } = props;

  const previousRoute = (locationBeforeTransition || {}).pathname || location.pathname;

  const nextRoute = (locationAfterTransition || {}).pathname || location.pathname;

  console.debug(`Route transition from [${previousRoute}] to [${nextRoute}]`);

  if (hideHeaderForRoute(nextRoute)) {
    return null;
  }

  const changeLocale = () => {
    if (locale === ENGLISH) {
      loadFrench();
    } else {
      loadEnglish();
    }
  };

  return (
    <div className="navigation-container">
      <div className="navigation">
        <div className="evo-logo">
          <Link to="/">
            <i />
          </Link>
        </div>

        <div className="user-info">
          <Authentication />
        </div>

        <nav className="navigation-links">
          <ul>
            <RoleBasedAccess uri="/cars">
              <li className="cars"><NavLink to="/cars" activeClassName="active"><FormattedMessage id="NAVIGATION.CARS" /></NavLink></li>
            </RoleBasedAccess>
            <RoleBasedAccess uri="/customer-search">
              <li className="customers"><NavLink to="/customer-search" activeClassName="active"><FormattedMessage id="NAVIGATION.MEMBERS" /></NavLink></li>
            </RoleBasedAccess>
            <RoleBasedAccess uri="/trips">
              <li className="trips"><NavLink to="/trips" activeClassName="active"><FormattedMessage id="NAVIGATION.TRIPS" /></NavLink></li>
            </RoleBasedAccess>
            <RoleBasedAccess uri="/ticket-search">
              <li className="tickets"><NavLink to="/ticket-search" activeClassName="active"><FormattedMessage id="NAVIGATION.TICKETS" /></NavLink></li>
            </RoleBasedAccess>
            <RoleBasedAccess uri={["/promotion-search", "/import", "/infleet", "/home-zone", "/aima-parking", "/fleet-management"]}>
            <li className="more floating-menu-container">
              <button type="button" className = "link-button"/>
              <ul className="floating-menu">
                <RoleBasedAccess uri="/promotion-search">
                  <li><NavLink to="/promotion-search" activeClassName="active"><FormattedMessage id="NAVIGATION.PROMOTIONS" /></NavLink></li>
                </RoleBasedAccess>
                <RoleBasedAccess uri="/import">
                  <li><NavLink to="/import" activeClassName="active"><FormattedMessage id="NAVIGATION.IMPORT_LOG" /></NavLink></li>
                </RoleBasedAccess>
                <RoleBasedAccess uri="/infleet">
                  <li><NavLink to="/infleet" activeClassName="active"><FormattedMessage id="NAVIGATION.INFLEETING" /></NavLink></li>
                </RoleBasedAccess>
                <RoleBasedAccess uri="/home-zone">
                  <li><NavLink to="/home-zone" activeClassName="active"><FormattedMessage id="NAVIGATION.HOMEZONE" /></NavLink></li>
                </RoleBasedAccess>
                <RoleBasedAccess uri="/aima-parking">
                  <li><NavLink to="/aima-parking" activeClassName="active"><FormattedMessage id="NAVIGATION.AIMA_PARKING" /></NavLink></li>
                </RoleBasedAccess>
                {/*
                <RoleBasedAccess uri="/wakeup-provider">
                  <li><NavLink to="/wakeup-provider" activeClassName="active"><FormattedMessage id="NAVIGATION.WAKEUP_PROVIDER" /></NavLink></li>
                </RoleBasedAccess>
                */}
                <RoleBasedAccess uri="/fleet-management">
                  <li><NavLink to="/fleet-management" activeClassName="active"><FormattedMessage id="NAVIGATION.FLEET_MANAGEMENT" /></NavLink></li>
                </RoleBasedAccess>
                <RoleBasedAccess uri="/parking-ticket">
                  <li><NavLink to="/parking-ticket" activeClassName="active"><FormattedMessage id="NAVIGATION.PARKING_TICKET" /></NavLink></li>
                </RoleBasedAccess>
              </ul>
            </li>
          </RoleBasedAccess>
          </ul>
        </nav>

        <div className={classNames('split-screen-btn', { 'split-screen-open': splitScreenIsOpen })}>
          <i onClick={toggleSplitScreen} />
        </div>

        <div className="switch-locale-btn">
          <i onClick={changeLocale}>{locale.toUpperCase()}</i>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  locale: state.intl.locale,
  routing: state.routing
});

RootNavigation = withRouter(RootNavigation);

export default connect(mapStateToProps, { toggleSplitScreen, loadEnglish, loadFrench })(RootNavigation);
