import React from "react";
import { compose } from 'recompose';
import { injectIntl } from "react-intl";
import Button from "js/components/elements/Button";
import { Dialog } from "primereact/components/dialog/Dialog";
import { withModal } from '../elements/ModalControls';

export const modalName = 'FileDownloadFailModal';

const footer = (intl, hideModal) =>
  <div className="ui-g-12 buttons-container">
    <Button
      className="button button-reset"
      label={intl.formatMessage({ id: "GLOBAL.OK" })}
      onClick={() => hideModal()}
    />
  </div>;

const FileDownloadFailModal = ({ intl, modalValue, hideModal }) =>
  <Dialog header={intl.formatMessage({ id: "MODALS.FILE_DOWNLOAD_FAIL.TITLE" })}
    width="550px" draggable={false} onHide={() => hideModal()}
    footer={footer(intl, hideModal)}
    modal={true}
    visible={modalValue.visible}
    closable={true}
  >
    <div className="ui-g-12">
      {intl.formatMessage({ id: "MODALS.FILE_DOWNLOAD_FAIL.MESSAGE" })}
    </div>
  </Dialog>;

export default compose(
  withModal(modalName),
  injectIntl
)(FileDownloadFailModal);
