import { isEmpty, isArray, toString, isNaN } from 'lodash';

export const isBlank = (value) => value === null || value === undefined || value.length === 0;

export function isNumber(value) {
  return (!isNaN(Number(value)) && !isNaN(value));
}

export function escapeSpecialCharacters(value) {

  if (value === undefined || value === null) {
    return '';
  }
  // Escape special characters to prevent XSS
  return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function arrayValuesToString(array, joinCharacter = ' | ') {

  if (isEmpty(array) || !isArray(array)) {
    console.warn(`Function 'arrayValuesToString' received a non array parameter`, JSON.stringify(array));
    return '';
  }

  return array.map(entry => entry.label || entry).join(joinCharacter);
}

export function slugify(string) {
  const a = 'àáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
  const b = 'aaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
  const p = new RegExp(a.split('').join('|'), 'g');

  /*CRA lint rules don't exclude valid regex escapes*/
  return string.toString().toLowerCase()
  /* eslint-disable */
    .replace(/\s+/g, '-') // Replace spaces with
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with ‘and’
    .replace(/[^\w\-]+/g, '_') // Replace all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple — with single -
    .replace(/^-+/, ''); // Trim — from start of text .replace(/-+$/, '') // Trim — from end of text
    /* eslint-enable */
}

export function translateToString(value) {
  switch (typeof value) {
    case "string":
      return value;
    case "number":
    case "boolean":
      return toString(value);
    default:
      return '';
  }
}

export function padString(s, length = 2, pad = '0', left = true) {
  s = s.toString();
  while(s.length < length) {
    s = left ? pad + s : s + pad;
  }
  return s;
}

export function trimObjectStrings(object){
  Object.keys(object).map(k => object[k] = typeof object[k] == 'string' ? (object[k].trim() === '' ? null:object[k].trim())  : object[k]);
}