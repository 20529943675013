import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import splitScreenActions from "../../actions/splitscreen.actions";
import RoleBasedAccess from '../rbac/RoleBasedAccess';
const { toggleSplitScreen } = splitScreenActions;

class AuthenticatedRoute extends Component {

  state = {}

  static getDerivedStateFromProps(nextProps/*, prevState*/) {

    const { screenLayout: { splitScreenIsOpen }, allowSplitScreen, toggleSplitScreen } = nextProps;

    // Switch to full screen automatically if split screen is not allowed for this route
    if (!allowSplitScreen && splitScreenIsOpen) {
      toggleSplitScreen();
    }

    return null;
  }

  render() {

    const { authentication: { authenticated }, component: Component, ...routeProps } = this.props;

    return (
      <Route {...routeProps} render={props => (
        authenticated
          ? (<RoleBasedAccess uri={routeProps.path} placeholder={<h4 className="text-center">You do not have access to this page, please see your system administrator</h4>}>
             <Component {...props} />
            </RoleBasedAccess>)
          : (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />)
      )} />
    );
  }
}

const mapStateToProps = state => ({
  screenLayout: state.screenLayout,
  authentication: state.authentication
});

export default connect(mapStateToProps, { toggleSplitScreen })(AuthenticatedRoute);
