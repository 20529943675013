import { connect } from 'react-redux';
import { get, toPairs } from 'lodash/fp';
import { compose, withProps } from 'recompose';
import { setModal } from 'js/utils/action.utils';

const connectModal = (name) => s => ({ modalValue: get(`api.modal.${name}`, s)||{} });

export const withModal = modalName => compose(
  connect(connectModal(modalName), { setModal }),
  withProps(({ setModal, onHide, onShow }) => ({
    hideModal: customModalName => {
      setModal(customModalName || modalName, 'visible', false);
      if (onHide) onHide();
    },
    showModal: (customModalName, data) => {
      const name = customModalName || modalName;
      setModal(name, 'visible', true);
      setModal(name, 'submitted', false);
      if (data) toPairs(data).forEach(p => setModal(name, p[0], p[1]));
      if (onShow) onShow();
    },
    setModalValue: (field, value, customModalName) =>
      setModal(customModalName || modalName, field, value)
  }))
);
