import { camelCase, update, findIndex, get, set, pipe, isUndefined, fromPairs, merge, omit, isFunction } from 'lodash/fp';
import { apiActions, otherVerbs, apiVerbs, modalVerbs, apiActionDelimiter, fileVerbs, fileActions } from 'js/utils/action.utils';
import actions from 'js/actions/api.actions';
import { setAll, getResult, splitDropLeft } from 'js/utils';
import { compose } from 'recompose';

export default (s = {}, a = {}) => {
  switch (a.type) {
    case apiActions.API_PENDING:
    case apiActions.API_SUCCESS:
    case apiActions.API_ERROR:
      return updateAPIState(getParams(s, a));

    case apiActions.API_OTHER:
      return updateOtherState(getParams(s, a));

    case actions.SET_FORM:
      return setForm(s, a);
    case actions.SET_TOUCHED:
      return setAll({ ['forms.' + a.payload.form + '._touched']: a.payload.value })(s);

    case actions.SET_FOR_SUBMIT:
      return setAll({ ['forms.' + a.payload.form + '._isForSubmit']: a.payload.readyToSubmit || {} })(s);

    case actions.SET_LOCAL:
      return setLocal(s, a);

    case modalVerbs.MODAL_SET:
      return setModal(s, a);

    case fileVerbs.RESET_FILE:
    case fileVerbs.RESET_FILE_ERROR:
    case fileVerbs.RESET_FILE_STATUS:
    case fileActions.FILE_PENDING:
    case fileActions.FILE_FAILED:
    case fileActions.FILE_SUCCESS:
      return setFile(s, a);

    case actions.SET_DIAGNOSTIC_STATUS:
      return setDiagnosticStatus(s, a);

    case actions.SET_RESET_DIAGNOSTIC_STATUS:
      return resetDiagnosticStatus(s);

    default:
      return s;
  }
};

const getParams = (state, action) => {
  const payload = { params: {}, ...action.payload };
  const [verb, act] = payload.action.split(apiActionDelimiter);
  const key = camelCase(act);
  return { state, type: action.type, payload, verb, key };
};

const updateAPIState = ({ state, type, payload: { data = {}, params = {}, error }, verb, key }) => {
  if(typeof data === 'string' || typeof data === 'number') {
    data = {data};
  }

  data && (data.params = omit(['origData'], params));
  const id = getId(data.id || params.id);
  const isPending = type === apiActions.API_PENDING;
  const isSuccess = type === apiActions.API_SUCCESS;
  const isUpdate = verb === apiVerbs.UPDATE;
  const isSearch = verb === apiVerbs.SEARCH;
  const isAdd = verb === apiVerbs.ADD;
  const isAddSuccess = verb === apiVerbs.ADD && type === apiActions.API_SUCCESS;

  const old = state[key] || {};
  const oldFormData = state.form;
  let oldData = old[id];

  if (params.form)
    data = merge(oldData, get(`forms.${params.form}`, state));
  let actionObj = {
    ...old,
    id,
    isLoading: isPending,
    error: isPending ? null : error,
    success: isPending ? null : isSuccess,
    fail: error ? camelCase(`${verb} ${key}`) : null,
    [id]: isSuccess ? data : oldData
  };

  if (verb === apiVerbs.ADD) {
    actionObj.addSuccess = isAddSuccess ? true : false;
  }

  if (isSuccess) {
    if (isSearch && id && actionObj[id])
      actionObj = set('default', actionObj[id], actionObj);
    if (isUpdate || isAdd) {
      actionObj = set('changed', data.id, actionObj);
      const result = getResult(old);
      const idx = Array.isArray(result) && result.findIndex(x => x.id === id || x[key + 'Id'] === id);
      if (isUpdate && idx > -1)
        actionObj = set(`default.results[${idx}]`, data, actionObj);
      else if (isAdd && idx === -1)
        actionObj = set(`default.results`, [data, ...result], actionObj);
    }
  }

  const modified = {
    [key]: actionObj,
    [`form.${key}`]: (isSuccess ? data : (oldFormData && oldFormData[key]))
  };

  const formname = params.form || params.formname;

  if (isSuccess && formname) {
    modified[`form.${formname}`] = data;
    if (isUpdate || isAdd)
      modified.forms = { ...state.forms, [formname]: null };
  }

  return setAll(modified)(state);
};

const setForm = (s, a) => {
  const path = splitDropLeft(1)(a.payload.path);
  const form = 'forms.' + (a.payload.form || a.payload.formname);
  const changes = {
    ['form.' + a.payload.path]: a.payload.value,
    [form + '._error.' + path]: a.payload.error || undefined,
    [form + '._isForSubmit.' + path]: a.payload.isForSubmit || undefined
  };
  if (!a.payload.isForSubmit) {
    const p = form + (path ? ('.' + path) : '');
    changes[p] = a.payload.reset ? undefined : a.payload.value;
  }
  return setAll(changes)(s);
};

const updateOtherState = ({ state, payload: { params }, verb, key }) => {
  params = params || {};
  const id = params.id;

  switch (verb) {
    case otherVerbs.RESET:
      return reset(state, params, key);
    case otherVerbs.CLEAR_ERROR:
      return { ...state, [key]: setAll({ isLoading: false, error: null })(state[key]) };
    case otherVerbs.SORT:
      return { ...state, [key]: setAll({ sorting: params })(state[key]) };
    case otherVerbs.TOGGLE:
      return { ...state, [key]: toggle(state[key], params) };
    case otherVerbs.COLLAPSE:
      return { ...state, [key]: toggleAll(state[key], id, false) };
    case otherVerbs.EXPAND:
      return { ...state, [key]: toggleAll(state[key], id, true) };
    case otherVerbs.NOTIFY:
      return { ...state, [key]: setAll({ notifications: x => (params ? [params] : []).concat(x || []) })(state[key]) };
    case otherVerbs.CLEAR_NOTIFICATION:
      return { ...state, [key]: setAll({ notifications: x => (x || []).filter(n => n.id !== params.id) })(state[key]) };
    case otherVerbs.CLEAR_SUCCESS:
      return { ...state, [key]: setAll({ success: null, addSuccess: null })(state[key]) };
    case otherVerbs.EDIT:
      return { ...state, form: { ...state.form, [key]: params.data } };
    case otherVerbs.SETFORM:
      return setAll({
        [`form.${key}.${params.path}`]: params.data,
        [`forms.${params.form}.${params.path}`]: params.data
      })(state);
    case otherVerbs.CLEAR_FORMS:
      return set('forms.' + key, {}, state);
    case otherVerbs.CLEAR_API:
      return set(key, {}, state);

    default:
      return state;
  }
};

const reset = (state, params, key) => {
  if (params.field && params.form && params.id && key && state[key]) {
    const originValue = get(key + '.' + params.id + '.' + params.field, state);
    const newState = set('form.' + key + '.' + params.field, originValue, state);
    return set('forms.' + params.form + '.' + params.field, null, newState);
  } else {
    const newKey = { isLoading: false, error: null, notifications: null, success: null, addSuccess: null };
    const id = `${getId(params.id)}`;
    if (!params.form)
      newKey[id] = null;
    const modified = { [key]: setAll(newKey)(state[key]) };

    if (params.form) {
      modified.forms = set(params.form, null)(state.forms);
      modified.form = set(params.no_prefix ? key : params.form, id === 'default' ? null : state[key][id])(state.form);
    }

    return { ...state, ...modified };
  }
};

const toggle = (state, params) => {
  const toggleIdName = Object.keys(params).find(x => x.indexOf('Id') > -1);
  const toggleIdValue = params[toggleIdName];
  const path = `${getId(params.id)}.results`;
  const idx = pipe(get(path), findIndex([toggleIdName, toggleIdValue]))(state);
  return update(`${path}[${idx}].expanded`, x => !x)(state);
};

const toggleAll = (state, id, value) => {
  const path = `${getId(id)}.results`;
  return setAll(
    fromPairs(get(path)(state).map((_, i) => [`${path}[${i}].expanded`, value])),
  )(state);
};

const getId = id => isUndefined(id) ? 'default' : id;

const setLocal = (s, a) => {
  const path = 'local.' + a.payload.path;
  const v = a.payload.value;
  return set(path, isFunction(v) ? v(get(path, s)) : v, s);
};

const setModal = (s, a) => {
  return set('modal.' + a.path + '.' + a.field, a.value, s);
};

const setFile = (s, a) => {
  switch(a.type) {
    case fileVerbs.RESET_FILE:
      return compose(
        set('file.' + a.path, {}),
        set('form.' + a.path + '.acceptedFiles', []),
        set('form.' + a.path + '.rejectedFiles', []),
        set('form.' + a.path + '.uploadFileError', "")
      )(s);
    case fileVerbs.RESET_FILE_ERROR:
      return compose(
        set('file.' + a.path + '.uploadFileError', ""),
        set('form.' + a.path + '.uploadFileError', "")
      )(s);
    case fileVerbs.RESET_FILE_STATUS:
      return compose(
        set('file.' + a.path + '.uploadFileError', ""),
        set('form.' + a.path + '.uploadFileError', ""),
        set('file.' + a.path + '.isLoading', false),
        set('file.' + a.path + '.success', null),
        set('file.' + a.path + '.error', null),
      )(s);
    case fileActions.FILE_PENDING:
      return compose(
        set('file.' + a.path + '.isLoading', true),
        set('file.' + a.path + '.success', null),
        set('file.' + a.path + '.error', null),
      )(s);
    case fileActions.FILE_FAILED:
      return compose(
        set('file.' + a.path + '.isLoading', false),
        set('file.' + a.path + '.success', false),
        set('file.' + a.path + '.error', a.error),
      )(s);
    case fileActions.FILE_SUCCESS:
      return compose(
        set('file.' + a.path + '.isLoading', false),
        set('file.' + a.path + '.success', true),
        set('file.' + a.path + '.error', null),
        set('file.' + a.path + '.data', a.data),
      )(s);
    default:
      return s;
  }
};

const setDiagnosticStatus = (s, a) => {
  let changes = {
    ['diagnosticResults.error.' + a.payload.command]: (a.payload.error === false) ? false : true,
    ['diagnosticResults.success.' + a.payload.command]: (a.payload.success === false) ? false : true,
    ['diagnosticResults.isLoading.' + a.payload.command]: (a.payload.isLoading === false) ? false : true,
    ['diagnosticResults.httpStatus.' + a.payload.command]: (a.payload.isLoading === false) ? get('payload.httpStatus', a) : ""
  };
  return setAll(changes)(s);
};

const resetDiagnosticStatus = (s) => {
  let changes = {
    diagnosticResults:{
      error:{},
      success:{},
      isLoading:{},
      httpStatus:{}
    }
  };
  return setAll(changes)(s);
};
