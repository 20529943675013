import React from 'react';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import {compose, withProps} from 'recompose';
import { select } from 'js/selectors';
import store from '../../store';

import { InputText, Dropdown } from './controls';
import apiActions from '../../actions/api.actions';
const { infleet } = apiActions;

export const ADD_CAR_FORM_NAME = 'addCarForm';

const regexPatterns ={
  plateNumber : /^[0-9]{3} [A-Z]{3}|[A-Z]{3} [0-9]{3}|[A-Z]{2}[0-9] [0-9]{2}[A-Z]$/,
  msisdn : /^\d{1,15}$/,
  iccid: /^[0-9]{19,20}$/,
  imsi: /^[0-9]{0,15}$/,
};

let regexValidate = (pattern, value) => pattern.test(value);

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

let changeTelemeterId = (formData, val) => {
  let telemeter = formData.telemeter;
  // map value back to its key
  let key = Object.keys(telemeter).filter(key => telemeter[key] === val)[0] || '';
  store.dispatch(change(ADD_CAR_FORM_NAME, "telemeterId", formData.telemeterPrefill[key]));
};

let validateInfleetForm = (...params) => {
  params[2].validateInfleet(params[0]);
  return sleep(0); //Temporary code till we get rid off redux forms. Asynvalidation expects a promise return
};

let selectInitialValues = (formData) => {
  if (!formData) {
    return {};
  }
  let fleet = formData.fleets && formData.fleets[0] && formData.fleets[0].fleetId;
  let vehicles = formData.vehicleModels;
  let carMake = vehicles && vehicles[0] && vehicles[0].makeId;
  let carModel = carMake && vehicles[0].models && vehicles[0].models[0] && vehicles[0].models[0].vehicleModelId;
  return {
    initialValues: {
      fleetId: fleet,
      carMake: carMake,
      carModel: carModel
    }
  };
};

let formatPlateNumber = (value, previousValue) => {
  value = value.toUpperCase();
  if (value.length > 7)
    return previousValue;
  if (value.length > 3 && value[3] !== ' '){
    return(value.slice(0,3) + ' ' + value.slice(3));
  }
  if (value.length === 3 && previousValue.length !== 4) {
    return value + ' ';
  }
  if (value.length === 4 && previousValue.length === 3) {
    return (previousValue + ' ' + value.slice(-1));
  }
  return value;
};

function validateVIN(val) {
  let re = /^[0-9_A-Z]+$/g;
  return re.test(val); //validates regex on val and return true if passed.
}

function validateForm(values) {

  const { vinNumber, telemeterId, plateNumber, iccid, imsi, msisdn, fleetId, telemeterModel, carrier, carModel, carMake } = values;
  const errors = {};

  if (!vinNumber) {
    errors.vinNumber = 'MODALS.ADD_CAR.FORM_VALIDATION.MISSING_VIN_NUMBER';
  } else if (vinNumber.length < 17) {
    errors.vinNumber = 'MODALS.ADD_CAR.FORM_VALIDATION.VIN_LENGTH_TOO_SHORT';
  } else if (vinNumber.length > 17) {
    errors.vinNumber = 'MODALS.ADD_CAR.FORM_VALIDATION.VIN_LENGTH_TOO_LONG';
  } else if (!validateVIN(vinNumber)) {
    errors.vinNumber = 'MODALS.ADD_CAR.FORM_VALIDATION.INVALID_VIN';
  }

  if (!telemeterId) {
    errors.telemeterId = 'MODALS.ADD_CAR.FORM_VALIDATION.MISSING_BOX_ID';
  } else if (telemeterId.length !== 15) {
    errors.telemeterId = 'MODALS.ADD_CAR.FORM_VALIDATION.INVALID_BOX_ID';
  }

  if (!telemeterModel) {
    errors.telemeterModel = 'MODALS.ADD_CAR.FORM_VALIDATION.MISSING_BOX_MODEL';
  }

  if (!plateNumber) {
    errors.plateNumber = 'MODALS.ADD_CAR.FORM_VALIDATION.MISSING_PLATE_NUMBER';
  } else if (!regexValidate(regexPatterns.plateNumber, plateNumber)) {
    errors.plateNumber = 'MODALS.ADD_CAR.FORM_VALIDATION.INVALID_PLATE_NUMBER';
  }

  if (!fleetId) {
    errors.fleetId = 'MODALS.ADD_CAR.FORM_VALIDATION.MISSING_FLEET';
  }

  if (!iccid) {
    errors.iccid = 'MODALS.ADD_CAR.FORM_VALIDATION.MISSING_ICCID';
  } else if (!regexValidate(regexPatterns.iccid, iccid)) {
    errors.iccid = 'MODALS.ADD_CAR.FORM_VALIDATION.INVALID_ICCID';
  }

  if (!imsi) {
    errors.imsi = 'MODALS.ADD_CAR.FORM_VALIDATION.MISSING_IMSI';
  } else if (!regexValidate(regexPatterns.imsi, imsi)) {
    errors.imsi = 'MODALS.ADD_CAR.FORM_VALIDATION.INVALID_IMSI';
  }

  if (!msisdn) {
    errors.msisdn = 'MODALS.ADD_CAR.FORM_VALIDATION.MISSING_MSISDN';
  } else if (!regexValidate(regexPatterns.msisdn, msisdn)) {
    errors.msisdn = 'MODALS.ADD_CAR.FORM_VALIDATION.INVALID_MSISDN';
  }

  if (!carrier) {
    errors.carrier = 'MODALS.ADD_CAR.FORM_VALIDATION.MISSING_CARRIER';
  }

  if (!carModel) {
    errors.carModel = 'MODALS.ADD_CAR.FORM_VALIDATION.MISSING_CAR_MODEL';
  }

  if (!carMake) {
    errors.carMake = 'MODALS.ADD_CAR.FORM_VALIDATION.MISSING_CAR_MAKE';
  }

  return errors;
}

let getFormDataValue = (data, dataType) => {
  switch (dataType) {
    case "fleet":
      return (data.fleets || [])
        .map(fleet => ({
          value: fleet.fleetId,
          label: fleet.fleetName
        }));
    case "carrier":
      return (data.carrier || [])
        .map(carrier => ({
          value: carrier,
          label: carrier
        }));
    case "boxModel":
      return (Object.keys(data.telemeter))
        .map(key => ({
          value: data.telemeter[key],
          label: key
        }));
    case "vehicleMake":
      return Object.keys(data.vehicleModels)
        .map(index => ({
          value: data.vehicleModels[index].makeId,
          label: data.vehicleModels[index].makeName
        }));
    default:
      return [];
  }
};

let getCarModels = (data, makeId) => {

  let selectedMake = data.vehicleModels.filter(make => make.makeId === makeId)[0];

  return !selectedMake ? [] : selectedMake.models
    .map(model => ({
      value: model.vehicleModelId,
      label: model.modelName + ' - ' + model.modelYear
    }));
};

let AddCarForm = ({
  handleSubmit, onCancel, intl: { formatMessage } , infleetError, clearErrorInfleet, invalid, formData, carModels
}) =>
  <form onSubmit={handleSubmit} id="add-car-form" className="ui-form">
    <div className="ui-g-12">
      <div className="ui-g-12 ui-md-6">

        <div className="ui-g-12">
          <h2><FormattedMessage id='MODALS.ADD_CAR.CAR_HEADER' /></h2>
        </div>

        <div className="ui-g-12">
          <Field type="text" component={Dropdown} name="fleetId" label={formatMessage({ id: 'MODALS.ADD_CAR.FLEET' })}
            options={getFormDataValue(formData, "fleet")} disableEmptyValue />
          <br />
        </div>

        <div className="ui-g-12">
          <Field type="text" component={InputText} name="plateNumber" onChange={clearErrorInfleet} normalize={formatPlateNumber} showError = {!(infleetError && infleetError.plateNumber)}
            label={formatMessage({ id: 'MODALS.ADD_CAR.PLATE_NUMBER' })}
          />
          {infleetError && infleetError.plateNumber === "invalid" ? <div className='input-field'><div className="input-field-error"><FormattedMessage id='MODALS.ADD_CAR.FORM_VALIDATION.INVALID_PLATE_NUMBER' /></div></div> : null}
          {infleetError && infleetError.plateNumber === "notUnique" ? <div className='input-field'><div className="input-field-error"><FormattedMessage id='MODALS.ADD_CAR.FORM_VALIDATION.NOT_UNIQUE_PLATE_NUMBER' /></div></div> : null}

          <br />
        </div>

        <div className="ui-g-12">
          <Field type="text" component={InputText} name="vinNumber" onChange={clearErrorInfleet} showError = {!(infleetError && infleetError.vinNumber)}
            label={formatMessage({ id: 'MODALS.ADD_CAR.VIN_NUMBER' })} />
            {infleetError && infleetError.vinNumber  === "invalid" ? <div className='input-field'><div className="input-field-error"><FormattedMessage id='MODALS.ADD_CAR.FORM_VALIDATION.INVALID_VIN' /></div></div> : null}
            {infleetError && infleetError.vinNumber === "notUnique" ? <div className='input-field'><div className="input-field-error"><FormattedMessage id='MODALS.ADD_CAR.FORM_VALIDATION.NOT_UNIQUE_VIN_NUMBER' /></div></div> : null}
          <br />
        </div>

        <div className="ui-g-12">
          <div className="ui-g-12">
            <Field type="text" component={Dropdown} name="telemeterModel"
                   label={formatMessage({ id: 'MODALS.ADD_CAR.BOX_MODEL' })}
                   onChange= { (e, val) => changeTelemeterId(formData, val) }
                   disableEmptyValue
                   options={getFormDataValue(formData, "boxModel")}
            />
            <br />
          </div>

          <div className="ui-g-12">
            <Field type="text" component={InputText} name="telemeterId" onChange={clearErrorInfleet} showError = {!(infleetError && infleetError.telemeterId)}
              label={formatMessage({ id: 'MODALS.ADD_CAR.BOX_ID' })} />
            {infleetError && infleetError.telemeterId === "invalid" ? <div className='input-field'><div className="input-field-error"><FormattedMessage id='MODALS.ADD_CAR.FORM_VALIDATION.INVALID_BOX_ID' /></div></div> : null}
            {infleetError && infleetError.telemeterId === "notUnique" ? <div className='input-field'><div className="input-field-error"><FormattedMessage id='MODALS.ADD_CAR.FORM_VALIDATION.NOT_UNIQUE_BOX_ID' /></div></div> : null}
            <br />
          </div>
          <br />
        </div>

        <div className="ui-g-12">
          <div className="ui-g-12">
            <Field type="text" component={Dropdown} name="carMake"
                   label={formatMessage({ id: 'MODALS.ADD_CAR.CAR_MAKE' })}
                   disableEmptyValue
                   options={getFormDataValue(formData, "vehicleMake")}
                   />
            <br />
          </div>

          <div className="ui-g-12">
            <Field type="text" component={Dropdown} name="carModel"
              label={formatMessage({ id: 'MODALS.ADD_CAR.CAR_MODEL' })}
                   disableEmptyValue
                   cacheOptions={false}
                   options={carModels}
            />
            <br />
          </div>
          <br />
        </div>

      </div>

      <div className="ui-g-12 ui-md-6">
        <div className="ui-g-12">
          <h2><FormattedMessage id='MODALS.ADD_CAR.SIM_HEADER' /></h2>
        </div>
        <div className="ui-g-12">
          <Field type="select" component={Dropdown} name="carrier" disableEmptyValue
            label="Provider" options={getFormDataValue(formData, "carrier")} />
          <br />
        </div>

        <div className="ui-g-12">
          <Field type="text" component={InputText} name="msisdn" onChange={clearErrorInfleet} showError = {!(infleetError && infleetError.msisdn)}
            label={formatMessage({ id: 'MODALS.ADD_CAR.MSISDN' })} />
            {infleetError && infleetError.msisdn === "invalid"? <div className='input-field'><div className="input-field-error"><FormattedMessage id='MODALS.ADD_CAR.FORM_VALIDATION.INVALID_MSISDN' /></div></div> : null}
            {infleetError && infleetError.msisdn === "notUnique" ? <div className='input-field'><div className="input-field-error"><FormattedMessage id='MODALS.ADD_CAR.FORM_VALIDATION.NOT_UNIQUE_MSISDN' /></div></div> : null}
          <br />
        </div>

        <div className="ui-g-12">
          <Field type="text" component={InputText} name="imsi" onChange={clearErrorInfleet} showError = {!(infleetError && infleetError.imsi)}
            label={formatMessage({ id: 'MODALS.ADD_CAR.IMSI' })} />
          {infleetError && infleetError.imsi === "invalid" ? <div className='input-field'><div className="input-field-error"><FormattedMessage id='MODALS.ADD_CAR.FORM_VALIDATION.INVALID_IMSI' /></div></div> : null}
          {infleetError && infleetError.imsi === "notUnique" ? <div className='input-field'><div className="input-field-error"><FormattedMessage id='MODALS.ADD_CAR.FORM_VALIDATION.NOT_UNIQUE_IMSI' /></div></div> : null}
          <br />
        </div>

        <div className="ui-g-12">
          <Field type="text" component={InputText} name="iccid" onChange={clearErrorInfleet}  showError = {!(infleetError && infleetError.iccid)}
            label={formatMessage({ id: 'MODALS.ADD_CAR.ICCID' })} />
            {infleetError && infleetError.iccid  === "invalid" ? <div className='input-field'><div className="input-field-error"><FormattedMessage id='MODALS.ADD_CAR.FORM_VALIDATION.INVALID_ICCID' /></div></div> : null}
            {infleetError && infleetError.iccid === "notUnique" ? <div className='input-field'><div className="input-field-error"><FormattedMessage id='MODALS.ADD_CAR.FORM_VALIDATION.NOT_UNIQUE_ICCID' /></div></div> : null}
          <br />
        </div>
      </div>
    </div>

    <div className="ui-g-12 buttons-container">
      <button className="button button-submit" type="submit" disabled={invalid}>
        <FormattedMessage id={'GLOBAL.ADD'} />
      </button>

      <button className="button button-reset" type="button" onClick={onCancel}>
        <FormattedMessage id='GLOBAL.CANCEL' />
      </button>
    </div>
  </form>;

const selector = formValueSelector(ADD_CAR_FORM_NAME);

const mapStateToProps = (state, props) => ({
  carModels: getCarModels(props.formData, selector(state, 'carMake'))
});

AddCarForm = connect(mapStateToProps)(AddCarForm);

export default compose(
  select({ infleet }),
  withProps(p => (selectInitialValues(p.formData))),
  reduxForm({
    form: ADD_CAR_FORM_NAME,
    validate: validateForm,
    asyncValidate: validateInfleetForm,
    asyncBlurFields: ['vinNumber', 'plateNumber', 'msisdn', 'iccid', 'imsi', 'telemeterId','fleetId']
  }),
  injectIntl
)(AddCarForm);
