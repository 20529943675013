import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { isArray } from 'lodash/fp';
import { AutoComplete } from 'primereact/components/autocomplete/AutoComplete';
import { compose, withProps, withHandlers } from 'recompose';
import { withState } from 'js/utils/with';

const Autocomplete = ({ input: { value }, label, required, multiple, minLength, placeholder, delay, labelKey,
  itemTemplate, selectedItemTemplate, meta: { error }, showError, setTouched, suggestions, selected, handleChange, suggest }) =>
  <div className={classNames('input-field input-autocomplete', { 'input-autocomplete-multiple': multiple === true })}>
    {label && <label>{label}</label>}{required && <span> *</span>}
    <br />
    <AutoComplete
      className={classNames({ 'input-error': showError })}
      onFocus={() => setTouched(false)}
      onBlur={() => setTouched(true)}
      minLength={minLength || 3}
      multiple={multiple || false}
      onChange={handleChange}
      value={value || selected}
      placeholder={placeholder || ''}
      delay={delay || 300}
      itemTemplate={itemTemplate || null}
      selectedItemTemplate={selectedItemTemplate || null}
      suggestions={suggestions}
      field={labelKey || null}
      completeMethod={suggest}
    />
    {showError && <div className="input-field-error"><FormattedMessage id={error} /></div>}
  </div>;

export default compose(
  withState('touched'),
  withState('selected'),
  withState('suggestions', []),
  withProps(({ meta: { error }, touched }) => ({ showError: touched && error })),
  withHandlers({
    handleChange: p => e => {
      const selected = e.value;
      if (p.multiple && !isArray(selected))
        console.warn(`Invalid value received for 'autocomplete' component`, selected);
      p.setSelected(selected);
      p.setTouched(true);
      p.input.onChange(selected);
    },
    suggest: p => ({ query }) =>
      p.setSuggestions((p.options || [])
        .filter(option => option && option.label && (option.label || '').toLowerCase().includes((query || '').toLowerCase())))
  }),
)(Autocomplete);