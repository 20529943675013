import 'rc-time-picker/assets/index.css';

import React from 'react';
import TimePicker from 'rc-time-picker';

const format = 'hh:mm:ss a';

export default ({ value, onChange }) =>
  <div className="input-field input-timepicker">
    <TimePicker
      className="TBD"
      format={format}
      showSecond={true}
      use12Hours={true}
      inputReadOnly={true}
      value={value}
      onChange={onChange}
    />
  </div>;