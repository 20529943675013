import { get, pipe, endsWith, dropRight, isArray } from 'lodash/fp';
import { compose, lifecycle, withProps, withState as _withState } from 'recompose';
import { withRouter } from "react-router";
import { parseQuery } from './query-string.utils';
import { getResult, getPagination } from '.';

export const withLocation = withProps(get('location'));
export const withRouteParams = withProps(p => {
  return get('match.params', p);
});
export const withQueryParams = withProps(pipe(get('location.search'), parseQuery));

export const withParams = compose(withRouter, withLocation, withRouteParams, withQueryParams);

export const detailId = a => (endsWith('Detail')(a) ? dropRight(6)(a) : a) + 'Id';

export const withDetail = a => withProps(props => {
  const id = props[detailId(a)] || '';
  return { [a]: (props[a] || {})[id] || {} };
});

export const withStore = arr => withProps(props => Object.assign({}, ...arr.map(a => {
  const p = props[a] || {};
  const id = props[detailId(a)] || p.id || '';
  return {
    [a + 'List']: getResult(p, 'default', a),
    [a + 'Pagination']: getPagination(p, id||'default'),
    [a + 'Sorting']: p['sorting'],
    [a]: p[id] || {},
    [a + 's']: p,
    [a + 'IsLoading']: p['isLoading'],
    [a + 'Success']: p['success'],
    [a + 'Error']: p['error'],
    [a + 'Fail']: p['fail']?{[p['fail']]: p['error']}:{},
    [a + 'Notifications']: p['notifications'],
    [a + 'Changed']: p[p.changed] || {},
  };
})));

export const withMount = f => lifecycle({
  componentDidMount() {
    f(this.props);
  }
});

export const withUnmount = f => lifecycle({
  componentWillUnmount() {
    f(this.props);
  }
});

export const withShouldUpdate = f => lifecycle({
  shouldComponentUpdate(p) {
    return f(this.props, p);
  }
});

export const withReceiveProps = f => lifecycle({
  UNSAFE_componentWillReceiveProps(p) {
    f(this.props, p);
  }
});

export const withUpdated = f => lifecycle({
  componentDidUpdate(p) {
    f(this.props, p);
  }
});

export const withState = (prop, val) => compose(...(isArray(prop) ? prop : [prop]).map(p =>
  _withState(p, 'set' + p[0].toUpperCase() + p.slice(1), val)));
