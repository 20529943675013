import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { hideHeaderForRoute } from '../../routes';
import { PrimaryBreadcrumbs, SecondaryBreadcrumbs } from '../../routes';

class RootBreadcrumbs extends Component {

  render() {
    const { splitScreenIsOpen, location: { pathname } } = this.props;

    if (hideHeaderForRoute(pathname)) {
      return null;
    }

    return (
      <div className="breadcrumbs-container">
        <PrimaryBreadcrumbs />
        {splitScreenIsOpen && <SecondaryBreadcrumbs />}
      </div>
    );
  }

}

export default withRouter(RootBreadcrumbs);
