import React from 'react';
import { keys, filter, map, fromPairs, toPairs, pipe, reduce, isString, isNaN, isFunction, isObject, isArray, isPlainObject, update, set, camelCase, isEmpty, isNil, drop, dropRight, orderBy, omit, flatten } from 'lodash/fp';
import { pickPagination } from './pagination.utils';
import moment from 'moment';
import ENV from 'js/configuration/env';

export const tap = x => {
  if (ENV.IS_DEVELOPMENT)
    console.log(x);
  return x;
};

export const use = o => f => f(o);

export const both = (f, g) => (...args) => f.apply(this, args) && g.apply(this, args);
export const or = (f, g) => (...args) => f.apply(this, args) || g.apply(this, args);

export const getChanges = (oldObj, newObj) => pipe(
  keys,
  filter(k => newObj[k] !== oldObj[k]),
  map(k => [k, newObj[k]]),
  fromPairs
)(newObj);

export const getResult = (data, id = 'default', actionName) => (data && data[id] && data[id].results) || (data && data[id] && data[id][actionName]) || (data && data[id]) || [];
export const getPagination = (data, id = 'default') => (data && data[id] && pickPagination(data[id])) || {};

export const getParams = s =>
  (s.match(/{(.*?)}/g) || [])
    .map(x => x.match(/{(.*)}/)[1]);

export const replace = (s, params) => reduce(
  (p, c) => p.replace(new RegExp('\\{' + c + '\\}', 'g'), params[c]),
  s,
  Object.keys(params)
);

export const toNumberType = s => isString(s) && s !== '' && !isNaN(+s) ? parseFloat(s, 10) : s;

export const setAll = a => s => reduce((state, [path, value]) => isFunction(value) ? update(path, value, state) : set(path, value, state), s, toPairs(a));

export const splitOne = (d, s) => {
  const n = s.indexOf(d);
  return n === -1 ? [s] : [s.slice(0, n), s.slice(n + 1)];
};

export const pascalCase = s => {
  const r = camelCase(s);
  return isEmpty(r) ? '' : (r.length === 0 ? r.toUpperCase() : r[0].toUpperCase() + r.slice(1));
};

export const titleCase = s => s.replace(/_/g, ' ').toLowerCase().replace(/\b[\S, _]/g, t => t.toUpperCase());

export const flattenChildren = children =>
  React.Children.toArray(children).reduce((p, c) => {
    p.push(c);
    if (c.props.children)
      p.concat(flattenChildren(c.props.children));
    return p;
  },
  []
);

export const getLeafValues = o => isArray(o) ? flatten(o.map(getLeafValues)) : (isObject(o) ? pipe(Object.keys, map(k => o[k]), map(getLeafValues), flatten)(o) : [o]);

export const isEmptyObj = o => getLeafValues(o).every(isNil);

export const hasKey = o => o && Object.keys(o).length > 0;

export const splitDropLeft = (n, d = '.') => s => drop(n, s.split(d)).join(d);
export const splitDropRight = (n, d = '.') => s => dropRight(n, s.split(d)).join(d);

export const sortDesc = orderBy([x => x], ['desc']);

export const formatOptionLabels = options => intl => options.map(o => ({ label: intl.formatMessage({ id: o.label }), value: o.value }));

export const replaceKey = (key, newKey, obj) => ({...omit([key], obj), [newKey]: obj[key]});

export const multiDispatch = (...args) => args.forEach(a => setTimeout(a, 0));

//export const deepOmitBy = f => obj => mapValues(deepOmitBy(f), omitBy(f));

export const hasValue = v => !isNil(v) && v !== '';

export const format = (s, ...params) => reduce((p, c) => p.replace(new RegExp('\\{' + c + '\\}', 'g'), params[c]), s, params.map((_, i) => i));

export const removeNil = pipe(toPairs, filter(p => !isNil(p[1])), map(p => [p[0], isPlainObject(p[1]) ? removeNil(p[1]) : p[1]]), fromPairs);

export const toSingleArray = x => x && !isArray(x) ? [x] : x;

export const flattenAll = (...params) => flatten(params);

export const scrollToElementTop = e => e.offsetTop > 0 && window.requestAnimationFrame(scrollToElementTop) && e.scrollTo({top: e.offsetTop, left: 0, behavior: 'smooth'});

// function to compare array or object:
export const isItemEqual = (value, other) => {
  if(value===other) return true;
	let type = Object.prototype.toString.call(value);
	if (type !== Object.prototype.toString.call(other)) return false;
	if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;
	let valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
	let otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
	if (valueLen !== otherLen) return false;

	let compare = (item1, item2) => {
		let itemType = Object.prototype.toString.call(item1);
		if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
			if (!isItemEqual(item1, item2)) return false;
		}
		else {
			if (itemType !== Object.prototype.toString.call(item2)) return false;
			if (itemType === '[object Function]') {  // convert to string to compare if it's a function
				if (item1.toString() !== item2.toString()) return false;
			} else {
				if (item1 !== item2) return false;
			}
		}
	};

	// Compare properties
	if (type === '[object Array]') {
		for (let i = 0; i < valueLen; i++) {
			if (compare(value[i], other[i]) === false) return false;
		}
	} else {
		for (let key in value) {
			if (value.hasOwnProperty(key)) {
				if (compare(value[key], other[key]) === false) return false;
			}
		}
	}

	// If nothing failed, return true
	return true;

};

export const addTime = (d, t) => // d and t are moment objects
  d.add(t.get('hour'), 'hours').add(t.get('minute'), 'minutes').add(t.get('second'), 'seconds');

export const to2Digits = d => d < 10 ? ('0' + d) : d.toString();

export const formatDuration = (d1, d2) => // d1, d2 are moment objects
  d1 && d2 &&
  use(moment.duration(d1.diff(d2)))(
    withProps(d => ({
      days: to2Digits(Math.floor(d.asDays())),
      hours: to2Digits(d.hours()),
      minutes: to2Digits(d.minutes()),
      seconds: to2Digits(d.seconds()),
      showDays: ({ days }) => +days > 0,
      showHours: ({ showDays, hours }) => showDays || +hours > 0,
      showMinutes: ({ showHours, minutes }) => showHours || +minutes > 0,
      showSeconds: ({ showMinutes, seconds }) => showMinutes || +seconds > 0
    }))(
      p => `${p.showDays ? (p.days + 'd:') : ''}${p.showHours ? (p.hours + 'h:') : ''}${p.showMinutes ? (p.minutes + 'm:') : ''}${p.showSeconds ? (p.seconds + 's') : ''}`
    )
  );

export const withProps = f => g => p => {
  const x = f(p);
  const t = Object.keys(x).reduce((p, c) => ({ ...p, [c]: isFunction(x[c]) ? x[c](p) : x[c] }), {});
  return g({ ...p, ...t });
};

// Convert millisecond to DD HH MM SS
export const convertMsToTime = d => {
  let r = {};
  d>86400000 && (r.days = Math.floor(d/86400000));
  d>3600000 && (r.hours = Math.floor(d%86400000/3600000));
  d>60000 && (r.minutes = Math.floor(d%3600000/60000));
  r.seconds = Math.floor(d%60000/1000);
  return r;
};
