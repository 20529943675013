import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { compose } from 'recompose';
import { find, camelCase, pickBy, omitBy, omit, endsWith, isString, merge, toPairs, fromPairs, pipe, get } from 'lodash/fp';
import apiActions from 'js/actions/api.actions';
import { combineSelectors } from 'js/utils/selector.util';
import { pascalCase, isEmptyObj } from 'js/utils';
import { withParams, withStore } from 'js/utils/with';

const api = s => s.api || {};
export const authentication = s => s.authentication || {};
export const screenLayout = s => s.screenLayout || {};
const modals = s => s.modals || {};
export const reduxForm = s => s.form || {};

const getAction = action => createSelector(
  api,
  a => a[action] || {}
);

const getForm = name => createSelector(
  api,
  a => (a.form && a.form[name]) || (name && {})
);

const getForms = name => createSelector(
  api,
  a => (a.forms && omit(['_touched', '_error', '_isForSubmit'], a.forms[name])) || {}
);

const getForm_ = (prop, name) => createSelector(
  api,
  get(`forms.${name}._${prop}`)
);

const getFormStatus = name => createSelector(
  getForms(name),
  f => ({ dirty: !isEmptyObj(f) })
);

const local = createSelector(
  api,
  a => a.local || {}
);

const parseActions = o => {
  const actions = Object.keys(pickBy(isString)(o));
  return {
    camelActions: actions.map(camelCase),
    pascalActions: actions.map(pascalCase)
  };
};

export const select = (selectors, actions) => {
  const { camelActions, pascalActions } = pipe(omit('form'), parseActions)(selectors);
  const otherSelectors = pipe(omitBy(isString), omit('form'))(selectors);
  const form = selectors.form || '';

  return compose(
    withParams,
    connect(
      combineSelectors({
        ...fromPairs(camelActions.map(a => [a, getAction(a)])),
        ...fromPairs(camelActions.map(a => [a + 'Form', getForm(a)])),
        ...fromPairs([
          [form + 'Form', getForm(form)],
          ['modifiedFields', getForms(form)],
          ['formTouched', getForm_('touched', form)],
          ['formError', getForm_('error', form)],
          ['formForSubmit', getForm_('isForSubmit', form)],
          ['formStatus', getFormStatus(form)]
        ]),
        ...otherSelectors
      }),
      merge(pickBy((_, k) => pascalActions.some(a => endsWith(a)(k)))(apiActions), actions)
    ),
    withStore(camelActions)
  );
};

export const getVisibleModalName = createSelector(
  modals,
  api,
  (ms, a) => find(m => ms[m] && ms[m].show, Object.keys(ms)) || (toPairs((a.modal || {})).find(p => p[1].visible) || [''])[0]
);

export const currentUser = createSelector(
  authentication,
  auth => auth.currentUser || {}
);

export const localCustomer = createSelector(
  local,
  l => l.customer || {}
);
