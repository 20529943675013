import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withModal } from 'js/components/elements/ModalControls';
import { Dialog } from 'primereact/components/dialog/Dialog';
import { compose, withProps } from 'recompose';
import { select } from 'js/selectors';

import apiActions from 'js/actions/api.actions';

import WithLoading from 'js/components/elements/WithLoading';

export const APPLY_PROMO_CODE_MODAL_NAME = 'ApplyPromoCodeModal';
const { applyPromo } = apiActions;

const ApplyPromoCodeModal = ({hideModal, handleConfirm, intl: { formatMessage }, modalValue, mappedRegions, applyPromoIsLoading}) =>
  <Dialog id="trip-adjustment" className="modal modal-sm" header={formatMessage({ id: 'MODALS.APPLY_PROMO_CODE.TITLE' })}
    modal={true} visible={modalValue.visible} onHide={()=>{hideModal(APPLY_PROMO_CODE_MODAL_NAME);}} width='750px'>
    <WithLoading isLoading={applyPromoIsLoading} showChildrenWhileLoading >
      <div className="ui-g-12">
        <p>
          <FormattedMessage id='MODALS.APPLY_PROMO_CODE.APPLY_PROMO_CODE_TO_CURRENT_CUSTOMER' />
        </p>
      </div>

      <div className="ui-g-12">
        <div className="text-highlighted">
          <p>
            <FormattedMessage id='MODALS.APPLY_PROMO_CODE.CODE_TYPE' />:&nbsp;<span>{modalValue.promotion&&modalValue.promotion.type}</span>
          </p>

          <p>
            <FormattedMessage id='MODALS.APPLY_PROMO_CODE.PROMO_CODE' />:&nbsp;<span>{modalValue.promotion&&modalValue.promotion.promoCode}</span>
          </p>

          <p>
            <FormattedMessage id='MODALS.APPLY_PROMO_CODE.FREE_MINUTES' />:&nbsp;<span>{modalValue.promotion&&modalValue.promotion.freeMinutes}</span>
          </p>

          <p>
            <FormattedMessage id='MODALS.APPLY_PROMO_CODE.DAYS_TO_EXPIRE' />:&nbsp;<span>{modalValue.promotion&&modalValue.promotion.daysValid}</span>
          </p>

          <p>
            <FormattedMessage id='MODALS.APPLY_PROMO_CODE.REGIONS' />:&nbsp;<span>{mappedRegions}</span>
          </p>
        </div>
      </div>

      <br />

      <div className="ui-g-12">
        <b>
          <FormattedMessage id='MODALS.APPLY_PROMO_CODE.REGISTRATION_DISCOUNT' />&nbsp;<span>{modalValue.promotion&&modalValue.promotion.regDiscount}</span>
        </b>
        <br />

        <p>
          <FormattedMessage id='MODALS.APPLY_PROMO_CODE.REMINDER' />
        </p>
      </div>

      <br />

      <div className="ui-g-12 buttons-container ui-dialog-footer">
          <button className="button button-submit" type="button" onClick={() => handleConfirm()}>
          <FormattedMessage id='GLOBAL.CONFIRM' />
        </button>

        <button className="button button-reset" type="button" onClick={()=>{hideModal(APPLY_PROMO_CODE_MODAL_NAME);}}>
          <FormattedMessage id='GLOBAL.CANCEL' />
        </button>
      </div>
    </WithLoading>
  </Dialog>;

export default compose(
  withModal(APPLY_PROMO_CODE_MODAL_NAME),
  select({applyPromo}),
  withProps(({modalValue}) => ({
    mappedRegions: ((modalValue && modalValue.promotion && modalValue.promotion.regions) || []).map(region => region.name).join(', '),
    promotion: {}
  })),
  injectIntl
)(ApplyPromoCodeModal);
